<template>
    <tr>
        <th
            v-for="(columnType, index) in columnTypes"
            :key="index"
            scope="col"
            class="align-middle border-b rounded-t p-2 truncate text-left"
            :class="{
                'bg-primary-300': columnType == primaryColumnType,
                'border-primary-700': columnType == primaryColumnType,
                'border-neutral-600': columnType != primaryColumnType,
                'hidden': emptyColumns.includes(index),
            }"
            :data-test-id="'column-type-' + index"
        >
            <label
                class="block text-small font-bold mb-1"
                :class="{ 'opacity-0': index !== 0 }"
            >{{ $t('ANALYSIS.COLUMN_TYPES') }}</label>
            <z-select
                :value="columnType"
                class="form-element"
                name="column"
                :has-error="wrongColumnType(index)"
                :disabled="isVerbatimColumnDisabled && columnType == primaryColumnType"
                @change="changeColumnType({index: index, newTypeId: isNaN($event) ? $event : parseInt($event)})"
            >
                <option
                    v-for="({ name, value }) in possibleColumnTypes"
                    :key="value"
                    :value="value"
                    :disabled="isTypeDisabled(value, index)"
                    :class="{ 'font-bold': value == primaryColumnType }"
                >
                    {{ name }}
                </option>
            </z-select>
        </th>
    </tr>
</template>

<script lang="ts">
import { defineComponent, onMounted, computed } from 'vue';
import ZSelect from '@/components/ui/Select.vue';
import dimensionTypeEnum from '@/domain/dimensionTypeEnum';
import useUserStore from '@/user/store';

export default defineComponent({
    components: {
        ZSelect
    },
    props: {
        columnTypes: { type: Array as () => Array<Number>, default: () => [] },
        possibleColumnTypes: { type: Array as () => Array<{ name: string, value: number }>, default: () => [] },
        primaryColumnType: { type: [String, Number], default: null },
        areTypesUnique: { type: Boolean, default: true },
        defaultType: { type: [String, Number], default: null },
        emptyColumns: { type: Array as () => Array<number>, default: () => [] },
        columnData: { type: Array as () => Array<Array<any>>, default: () => [] },
        isVerbatimColumnDisabled: { type: Boolean, default: false },
        wrongColumnTypeIndexes: { type: Array as () => Array<number>, default: () => [] },
    },
    setup(props, context) {
        const { state } = useUserStore();

        function changeColumnType({ index, newTypeId }) {
            context.emit(
                'columnTypesChanged',
                props.columnTypes.map((typeId, i) => {
                    if (index === i) {
                        return newTypeId;
                    } else if (props.areTypesUnique && typeId === newTypeId) {
                        return props.defaultType;
                    }
                    return typeId;
                })
            );
        }

        const indexesOfNoCategoryTypeAllowed = computed(() => {
            return props.columnData.filter(data => data.length >= 100 && data.slice(0, 100).length === [...new Set(data.slice(0, 100))].length).map((_, index) => index);
        });

        const isTypeDisabled = (value, index) => {
            if (value === dimensionTypeEnum.VERBATIM && props.isVerbatimColumnDisabled) return true;
            if (value !== dimensionTypeEnum.CATEGORY || state.isSuperAdmin) return false;

            return indexesOfNoCategoryTypeAllowed.value.includes(index);
        };

        const wrongColumnType = (index: number): boolean => {
            return props.wrongColumnTypeIndexes.includes(index);
        };

        onMounted(() => {
            // set empty columns to 'delete'
            props.emptyColumns.forEach((column) => {
                changeColumnType({ index: column, newTypeId: dimensionTypeEnum.DELETE });
            });
        });

        return {
            changeColumnType,
            isTypeDisabled,
            wrongColumnType,
        };
    },
});
</script>
