import { RelativeDate } from '@/dashboard/common/types/filterInterface';
import dayjs from 'dayjs';

export default function getRangeFromRelativeDate(value: RelativeDate): number[] {
    const { action, amount, interval } = value;
    const now = dayjs().utc();
    if (action === 'last') {
        const lastFrom = amount === 1 ?
            now.subtract(1, interval) :
            now.subtract(amount - 1, interval).startOf(interval);
        return [
            lastFrom.utc().startOf('day').unix(),
            now.endOf(interval).utc().endOf('day').unix()
        ];
    } else {
        return [
            now.subtract(amount, interval)
                .startOf(interval === 'week' ? 'isoWeek': interval)
                .utc().startOf('day').unix(),
            now.subtract(1, interval)
                .endOf(interval === 'week' ? 'isoWeek': interval)
                .utc().endOf('day').unix()
        ];
    }
}
