<template>
    <div>
        <div
            class="quota-usage-panel"
            data-test-id="quota-usage-panel"
        >
            <h3 class="text-black">
                {{ isQuotaExceeded ? $t('ANALYSIS.QUOTA_EXCEED_QUOTA_TEXT') : $t('ANALYSIS.QUOTA_WARNING') }}
                <div class="popup-close">
                    <font-awesome-icon
                        class="close-icon"
                        :icon="['fal', 'times']"
                        @click="$emit('closePopup')"
                    />
                </div>
            </h3>
            <div
                v-if="!isQuotaExceeded"
                class="quota-usage-info text-neutral-900"
            >
                <div class="quota-usage-info1">
                    0
                </div>
                <div
                    class="quota-usage-info2"
                    :style="'color:' + eightyPercentColor"
                >
                    80%
                </div>
                <div class="quota-usage-info3">
                    {{ maximumValue }}K
                </div>
            </div>
            <div
                v-if="!isQuotaExceeded"
                class="progress-bar"
                :style="'background: linear-gradient(to right,' + primaryColor +',' + primaryColor + ' ' + actualPercentage + '%,' + secondaryColor +' '+ actualPercentage +'%,'+ secondaryColor + ' '+ calculatedPercentage +'%, #ebeff5 ' + calculatedPercentage + '%);'"
            >
                <span :style="'margin-left: 80%; font-size: 12px; width: 100%; text-align: left; color:' + eightyPercentColor">
                    |
                </span>
                <font-awesome-icon
                    v-if="(((usedQuota + allTextUnits)/quotaLimit * 100) > 100) && expandedInfo === false"
                    :icon="['fas', 'angle-up']"
                    class="fa-icons quota-arrow text-black"
                    @click="expandedInfo = true"
                />
            </div>
        </div>
        <div
            v-if="!isQuotaExceeded"
            class="progress-bar-info"
        >
            <b>{{ $t('ANALYSIS.QUOTA_80_PERCENT_WARNING_DESCRIPTION', 'Uploading this dataset might exceed the 80% of your Text Analysis quota defined in your Plan. During the actual quota calculation the empty cells are ignored, so the final quota consumption could be less than the estimated consumption.') }}</b>
        </div>
        <div
            v-else
            class="progress-bar-info"
        >
            {{ $t('ANALYSIS.QUOTA_EXCEED_QUOTA_DESCRIPTION', 'Based on the number of rows of this dataset you might have reached your maximum available text analysis quota. The calculation before the text analysis counts every cell in every Text Analysis column type, even if there are empty cells, this is just an estimation of your quota usage. During the analysis process, the quota calculation ignores the empty cells, so the final quota consumption could be less than the estimated consumption. You can check your quota usage any time in Settings / Plan Usage. After the text analysis process we will let you know if you have used all of your quota defined in your group\'s plan.') }}
            <br><br>
            {{ $t('ANALYSIS.QUOTA_EXCEED_QUOTA_DESCRIPTION_PART2', 'If you wish to proceed, your dataset might only be partially processed depending on the actual amount of non-empty cells.') }}
        </div>
        <div class="submit-button">
            <z-button
                v-if="missingQuota > 0 && !isQuotaAlreadyExceeded"
                variant="secondary"
                data-test-id="downsize"
                @click="$emit('startAnalysis', true)"
            >
                {{ $t('BUTTONS.PROCEED', 'Proceed') }}
            </z-button>
            <z-button
                v-if="!isQuotaExceeded"
                data-test-id="analyze"
                @click="$emit('startAnalysis', false)"
            >
                {{ $t('BUTTONS.ANALIZE') }}
            </z-button>
        </div>
    </div>
</template>

<script>
import { FontAwesomeIcon } from '@/utils/icons';
import useUserStore from '@/user/store';


export default {
    components: {
        FontAwesomeIcon,

    },
    props: {
        quotaLimit: { type: Number, default: null },
        allTextUnits: { type: Number, default: null },
        usedQuota: { type: Number, default: null },
        accountManagerEmail: { type: String, default: '' },
    },
    setup() {
        const { primaryColor: primary_color } = useUserStore().getters;

        return { primary_color };
    },
    data() {
        return {
            expandedInfo: false,
            tooltipText: this.$t('ANALYSIS.QUOTA_USAGE_TOOLTIP_TEXT'),
        };
    },

    computed: {
        actualPercentage() {
            return Math.round((this.usedQuota / this.quotaLimit) * 100);
        },
        calculatedPercentage() {
            return Math.round(((this.usedQuota + this.allTextUnits) / this.quotaLimit) * 100);
        },
        isQuotaAlreadyExceeded() {
            return this.usedQuota >= this.quotaLimit;
        },
        missingQuota() {
            if (this.isQuotaAlreadyExceeded) {
                return this.allTextUnits;
            }
            return (this.usedQuota + this.allTextUnits) - this.quotaLimit;
        },
        isQuotaExceeded() {
            return this.missingQuota > 0;
        },
        maximumValue() {
            return this.quotaLimit/1000;
        },
        primaryColor() {
            if (((this.usedQuota + this.allTextUnits)/this.quotaLimit) * 100 > 80) {
                return '#f03';
            }
            else {
                return this.primary_color;
            }
        },
        secondaryColor() {
            if (((this.usedQuota + this.allTextUnits)/this.quotaLimit) * 100 > 80) {
                return '#fab';
            }
            else {
                return '#baedea';
            }
        },
        eightyPercentColor() {
            if (((this.usedQuota + this.allTextUnits)/this.quotaLimit) * 100 > 80) {
                return '#f03';
            }
            else {
                return '#666';
            }
        },
        tooltip() {
            return this.tooltipText;
        },
        downgradeMaxQuotaText() {
            return this.$t('ANALYSIS.QUOTA_DOWNSIZE_QUOTA', {
                quota: this.quotaLimit,
                defaultValue: 'According to your Team plan your text analysis quota is limited in {{quota}} rows.'
            });
        },
        downgradeAvailableQuotaText() {
            return this.$t('ANALYSIS.QUOTA_DOWNSIZE_AVAILABLE_QUOTA', {
                quota: this.isQuotaAlreadyExceeded ? 0 : (this.quotaLimit - this.usedQuota),
                defaultValue: 'Currently your available quota is {{quota}} rows.'
            });
        },
        downgradeAnalyzedRowsText() {
            return this.$t('ANALYSIS.QUOTA_DOWNSIZE_ANALYZED_ROWS', {
                rows: this.quotaLimit - this.usedQuota,
                defaultValue: 'If you choose to downsize only the first {{rows}} rows of your dataset will be processed.'
            });
        },
    },

    watch: {
        allTextUnits() {
            this.expandedInfo = this.usedQuota + this.allTextUnits >= this.quotaLimit;
        },
    },

    mounted() {
        this.expandedInfo = this.usedQuota + this.allTextUnits >= this.quotaLimit;
    },
};
</script>

<style lang="less" scoped>
    .popup-close {
        cursor: pointer;
        float: right;
        width: max-content;
    }
    .progress-bar {
        height: 9px;
        border-radius: 4px;
        margin: 0 10px;
    }

    h3 {
        font-size: 16px;
        font-weight: 700;
        margin: 10px;
    }

    h3, .fa-icons {
        font-size: 16px;
    }

    .quota-usage-info{
        font-size: 10px;
        display: -webkit-box;
        display: flex;
        -webkit-box-pack: justify;
        justify-content: space-between;
        -webkit-box-align: end;
        align-items: flex-end;
        margin: 0 10px;
    }

    .quota-usage-info2 {
        text-align: right;
        width: 70%;
    }

    .quota-arrow{
        margin-left: 42%;
        margin-bottom: 2%;
    }

    .analysis-info{
        text-align: left;
        margin: 0 10px;

        .label-text{
            font-size: 13px;
            margin-left: 5px;
            vertical-align: text-top;
        }
        .fa-icons {
            font-size: 13px;
        }
    }

    .info-icon{
        font-size: 1.1vw;
    }

    .contact-account-manager {
        padding: 15px;
    }

    .progress-bar-info {
        font-size: 13px;
        margin: 10px;
    }

    .submit-button {
        margin-bottom: 10px;
        margin-right: 10px;
        text-align: right;
    }
</style>
