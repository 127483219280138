import createAuth0Client, {Auth0Client} from '@auth0/auth0-spa-js';
import storage from '@/utils/storage';
import globals from '@/utils/globals';
import auth0MockForPrinter from '@/utils/auth0MockForPrinter.ts';
import auth0MockForCypress from '@/utils/auth0MockForCypress.js';

let cachedClient;

const redirectUrl = new URL(window.location.origin + '/callback');
redirectUrl.search = `r=${window.location.href}`;

/**
 * @param {boolean} forceReInit
 * @returns {Promise<Auth0Client|*>}
 */
export async function getClient(forceReInit = false) {
    if (storage.getItem('cypress_test_auth')) {
        return auth0MockForCypress;
    }
    if (await auth0MockForPrinter.getTokenSilently()) {
        return auth0MockForPrinter;
    }
    if (cachedClient && !forceReInit) return cachedClient;

    try {
        cachedClient = await createAuth0Client({
            domain: globals.getAuth0Domain(),
            client_id: globals.getAuth0Client(),
            audience: globals.getAuth0Audience(),
            redirect_uri: redirectUrl.toString(),
            useRefreshTokens: true,
            cacheLocation: globals.getAuth0Localstorage() ? 'localstorage' : 'memory',
        });
        return cachedClient;
    } catch (e) {
        Sentry.captureException(e);
        const client = new Auth0Client({
            domain: globals.getAuth0Domain(),
            client_id: globals.getAuth0Client(),
            audience: globals.getAuth0Audience(),
            redirect_uri: redirectUrl.toString(),
            useRefreshTokens: true,
            cacheLocation: globals.getAuth0Localstorage() ? 'localstorage' : 'memory',
        });
        await client.loginWithRedirect();
    }
}

export async function handleCallback() {
    try {
        const client = await getClient();
        await client.handleRedirectCallback();
        try {
            const currentSearchParams = new URLSearchParams(new URL(window.location.href).search);
            window.location.replace(new URL(currentSearchParams.get('r')).toString());
        } catch (e) {
            window.location.replace('/');
        }
    } catch (e) {
        Sentry.captureException(e);
        const client = await getClient();
        return client.logout();
    }
}

export async function signUp() {
    const client = await getClient();
    window.location.replace(await client.buildAuthorizeUrl({ fragment: 'signup' }));
}
