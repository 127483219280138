import { reactive, computed } from 'vue';
import * as datasetApi from '@/dashboard/api/datasetApi';
import * as aiApi from '@/dashboard/textAnalysis/api/ai';
import { AiResult, AiResultStatus, PromptType, Topic } from '../utils/aiTypes';

import useCommonStore from '@/dashboard/common/store/commonStore';
const { state: commonState } = useCommonStore();

import useUserStore from '@/user/store';
const { state: userState } = useUserStore();

import useFilterStore from '@/dashboard/common/store/filterStore';
const { getters: filterGetters } = useFilterStore();

import useDrillDownStore from '@/dashboard/common/store/drillDown';
const { state: drillDownState } = useDrillDownStore();

import { Filter, FilterSubTypeEnum, FilterTypeEnum, OperatorTypeEnum } from '@/dashboard/common/types/filterInterface';
import getRangeFromRelativeDate from '@/dashboard/common/components/Filter/DefaultDateFilter/getRangeFromRelativeDate';
import { dimensionTypeId } from '@/domain/dataset/dimensionTypeId';
import { datasetTypeId } from '@/domain/dataset/datasetTypeId';

import { toaster, removeToaster } from '@/utils/toaster';
import wait from '@/utils/wait';

import { saveAs } from 'file-saver';
import i18next from 'i18next';

import useSubscriptionStore from '@/subscription/store';
const { getters: subscriptionGetters } = useSubscriptionStore();

import { isAISummaryEnabled } from '@/features/isFeatureEnabled.js';
import planFeatureTypeEnum from '@/domain/planFeatureTypeEnum';

import useDataseriesStore from '@/dashboard/common/store/dataseriesStore';
const { getters: dataseriesGetters } = useDataseriesStore();

import { languages } from '@/domain/analysisLanguageEnum';

let controller = new AbortController();

interface AiState {
    aiSideTabOpen: boolean,
    prompType: PromptType|null,
    topics: Array<Topic>,
    metric: string,
    timeframe: string,
    error: string,
    primaryMetricQuestion?: any,
    results: Array<AiResult>,
    resultSearch: { type: string|null, value: string|number|null, label: string|null },
    result: AiResult|null,
    polling: boolean,
    historyView: boolean,
    generatingToasterId: string,
    customPrompt: string,
    shouldRegenerate: boolean,
}

const getInitialState = (): AiState => ({
    aiSideTabOpen: false,
    prompType: null,
    topics: [],
    metric: '',
    timeframe: '',
    error: '',
    primaryMetricQuestion: null,
    results: [],
    resultSearch: { type: null, value: null, label: null },
    result: null,
    polling: false,
    historyView: false,
    generatingToasterId: '',
    customPrompt: '',
    shouldRegenerate: false,
});

export const state = reactive(getInitialState());

const isAiSummaryEnabled = computed<boolean>(() => {
    return (
        isAISummaryEnabled()
        && subscriptionGetters.canUseFeature.value(planFeatureTypeEnum.AI_INSIGHTS)
        && commonState.dataset.dimension_definitions.some(d => (
            d.type === dimensionTypeId.VERBATIM
            && (d.avgVerbatimLength == null || d.avgVerbatimLength && d.avgVerbatimLength < 2000))
        )
    );
});

export const makeActions = (state: AiState & { columnId: string }) => {
    const actions = {
        openAiSideTab: async ({ gotoHistory = false }: { gotoHistory?: boolean } = { gotoHistory: false }): Promise<void> => {
            state.aiSideTabOpen = true;
            state.historyView = gotoHistory;
        },
        closeAiSideTab: (): void => {
            state.aiSideTabOpen = false;
        },
        setPrompType: (type: PromptType|null): void => {
            state.prompType = type;
        },
        setTopics: (topics: Array<Topic>): void => {
            state.topics = topics;
        },
        setMetric: (metric: string): void => {
            state.metric = metric;
        },
        setTimeframe: (timeframe: string): void => {
            state.timeframe = timeframe;
        },
        getPrimaryMetricQuestion: async () => {
            const responses = await datasetApi.getResponseCounts(commonState.dataset.id);

            const filters = filterGetters.getFilters();

            state.primaryMetricQuestion = commonState.dataset.dimension_definitions
                .filter(d => [
                    dimensionTypeId.CES_5_SCALE,
                    dimensionTypeId.CES_7_SCALE,
                    dimensionTypeId.CSAT,
                    dimensionTypeId.NPS
                ].includes(d.type))
                .filter(d => filters.every(f => f.dimensionId !== d.id))
                .sort((d1,d2) => {
                    if (d1.type === d2.type) {
                        const r1 = responses.find(f => f.id === d1.id)?.value || 0;
                        const r2 = responses.find(f => f.id === d2.id)?.value || 0;

                        return r2 - r1;
                    }
                    if (d1.type === dimensionTypeId.NPS) return -1;
                    if (d2.type === dimensionTypeId.NPS) return 1;
                    if (d1.type === dimensionTypeId.CSAT) return -1;
                    if (d2.type === dimensionTypeId.CSAT) return 1;
                    if (d1.type === dimensionTypeId.CES_5_SCALE || d1.type === dimensionTypeId.CES_7_SCALE) return -1;
                    if (d2.type === dimensionTypeId.CES_5_SCALE || d2.type === dimensionTypeId.CES_7_SCALE) return 1;

                    return 1;
                })[0];
        },
        getAiFilters(): Array<Filter> {
            const filters: Array<Filter> = [];

            if (state.topics.length > 0) {
                const labels = state.topics.filter(t => t.type !== 'customLabel').map(t => +t.id);

                if (labels.length > 0) {
                    filters.push({
                        dimensionId: state.columnId,
                        isMultiSelect: false,
                        operator: OperatorTypeEnum.oneOf,
                        type: FilterTypeEnum.label,
                        value: labels
                    });
                }

                const customLabels = state.topics.filter(t => t.type === 'customLabel').map(t => +t.id);

                if (customLabels.length > 0) {
                    filters.push({
                        dimensionId: state.columnId,
                        isMultiSelect: false,
                        operator: OperatorTypeEnum.oneOf,
                        type: FilterTypeEnum.customLabel,
                        value: customLabels
                    });
                }
            }

            if (state.primaryMetricQuestion && state.metric) {
                const question = state.primaryMetricQuestion;

                let type;

                switch (question.type) {
                case dimensionTypeId.NPS:
                    type = FilterTypeEnum.npsSegment;
                    break;
                case dimensionTypeId.CES_5_SCALE:
                    type = FilterTypeEnum.ces5Segment;
                    break;
                case dimensionTypeId.CES_7_SCALE:
                    type = FilterTypeEnum.ces7Segment;
                    break;
                case dimensionTypeId.CSAT:
                    type = FilterTypeEnum.csatSegment;
                }

                if (!type) throw new Error('wrong metric type');

                filters.push({
                    dimensionId: question.id,
                    isMultiSelect: false,
                    operator: OperatorTypeEnum.oneOf,
                    type,
                    value: [state.metric],
                });
            }

            if (state.timeframe) {
                let relativeDate;

                switch (state.timeframe) {
                case 'lastday':
                    relativeDate = { action: 'last', interval: 'day', amount: 1 };
                    break;
                case 'last7days':
                    relativeDate = { action: 'last', interval: 'day', amount: 7 };
                    break;
                case 'last14days':
                    relativeDate = { action: 'last', interval: 'day', amount: 14 };
                    break;
                case 'last30days':
                    relativeDate = { action: 'last', interval: 'day', amount: 30 };
                    break;
                case 'previousday':
                    relativeDate = { action: 'prev', interval: 'day', amount: 1 };
                    break;
                case 'previousweek':
                    relativeDate = { action: 'prev', interval: 'week', amount: 1 };
                    break;
                case 'previous2weeks':
                    relativeDate = { action: 'prev', interval: 'week', amount: 2 };
                    break;
                case 'previousmonth':
                    relativeDate = { action: 'prev', interval: 'month', amount: 1 };
                    break;
                case 'previousyear':
                    relativeDate = { action: 'prev', interval: 'year', amount: 1 };
                }

                if (!relativeDate) {
                    throw new Error('wrong date');
                }

                const submittedAt = commonState.dataset.dimension_definitions.find(d => d.name === 'Submitted at' && d.is_metadata && ![datasetTypeId.excel, datasetTypeId.website_crawler, datasetTypeId.pdf_extractor].includes(commonState.dataset.type));
                const sentAt  = commonState.dataset.dimension_definitions.find(d => d.name === 'Sent at' && d.type === dimensionTypeId.DATE);
                const timestamp  = commonState.dataset.dimension_definitions.find(d => d.id === 'timestamp' && d.type === dimensionTypeId.DATE);

                const dimension = submittedAt || sentAt || timestamp;

                if (!dimension) {
                    throw new Error('no time dimension');
                }

                filters.push({
                    dimensionId: dimension.id,
                    isMultiSelect: false,
                    operator: OperatorTypeEnum.between,
                    subType: FilterSubTypeEnum.date,
                    type: FilterTypeEnum.dimension,
                    value: getRangeFromRelativeDate(relativeDate)
                });
            }

            return filters;
        },
        async generateAiResult(): Promise<number> {
            if (!state.prompType) throw new Error('prompType missing');

            const id = await aiApi.generateAiResult({
                datasetId: commonState.dataset.id,
                dimensionId: state.columnId,
                promptType: state.prompType,
                customPrompt: state.prompType === PromptType.FREETEXT_PROMPT ? state.customPrompt : undefined,
                filters: [{
                    aiFilters: actions.getAiFilters(),
                    dashboardFilters: filterGetters.getFilters()
                }],
            });

            return id;
        },
        generateDrilldownAiResult: async (): Promise<number> => {
            if (!state.prompType) throw new Error('prompType missing');

            const id = await aiApi.generateAiResult({
                datasetId: commonState.dataset.id,
                dimensionId: state.columnId,
                promptType: state.prompType,
                customPrompt: state.prompType === PromptType.FREETEXT_PROMPT ? state.customPrompt : undefined,
                filters: [{
                    aiFilters: [],
                    dashboardFilters: [...drillDownState.drillDownFilter, ...filterGetters.getFilters()]
                }],
            });

            return id;
        },
        async regenerateDrilldownAiResult(): Promise<number> {
            if (!state.result) throw new Error('result missing');
            if (!state.prompType) throw new Error('prompType missing');

            const id = await aiApi.regenerateAiResult({
                id: state.result.id,
                name: state.result.name,
                promptType: state.prompType,
                customPrompt: state.prompType === PromptType.FREETEXT_PROMPT ? state.customPrompt : undefined,
                filters: [{
                    aiFilters: [],
                    dashboardFilters: Object.values(state.result.filters)[0].dashboardFilters
                }],
            });

            return id;
        },
        async regenerateAiResult(): Promise<number> {
            if (!state.result) throw new Error('result missing');
            if (!state.prompType) throw new Error('prompType missing');

            const id = await aiApi.regenerateAiResult({
                id: state.result.id,
                name: state.result.name,
                promptType: state.prompType,
                customPrompt: state.prompType === PromptType.FREETEXT_PROMPT ? state.customPrompt : undefined,
                filters: [{
                    aiFilters: actions.getAiFilters(),
                    dashboardFilters: Object.values(state.result.filters)[0].dashboardFilters
                }],
            });

            return id;
        },
        async generateAiComparison(): Promise<number> {
            const id = await aiApi.generateAiResult({
                datasetId: commonState.dataset.id,
                dimensionId: state.columnId,
                promptType: PromptType.COMPARE_CONTEXTS,
                filters: dataseriesGetters.selectedDataseries.value.map(ds => ({ aiFilters: [], dashboardFilters: ds.filters })),
            });

            return id;
        },
        async regenerateAiComparison(): Promise<number> {
            if (!state.result) throw new Error('result missing');

            const id = await aiApi.regenerateAiResult({
                id: state.result.id,
                name: state.result.name,
                promptType: PromptType.COMPARE_CONTEXTS,
                filters: Object.values(state.result!.filters)
            });

            return id;
        },
        setError: (val: string): void => {
            state.error = val;
        },
        handleError: (e: any): void => {
            if (e.response?.status === 413) {
                actions.setError(i18next.t('AI_INSIGHT.SAMPLE_SIZE_ERROR_TOO_SMALL', 'Using the data defined by the current filterset sampling would result in a sample size below 1% of the input data which would not provide significant results. Please apply filters and focus your data.'));
            }
            else if (e.response?.status === 422) {
                actions.setError(i18next.t('AI_INSIGHT.SAMPLE_SIZE_ERROR_EMPTY_SAMPLE', 'Using the data defined by the current filterset sampling would result in a zero sample size. Please modify the filters.'));
            }
            else {
                Sentry.captureException(e);
                toaster.error(i18next.t('GENERAL.SOMETHING_WENT_WRONG'));
            }
        },
        getAiResult: async (resultId: number, shouldOpenSideTab = false): Promise<AiResult> => {
            const aiResult = await aiApi.getAiResult(resultId);

            if (shouldOpenSideTab) {
                actions.openAiSideTab();
            }

            return actions.setAiResult(aiResult);
        },
        setAiResult(aiResult: AiResult) {
            if (!aiResult.isReadByCreator && aiResult.status !== AiResultStatus.GENERATING && !state.historyView && state.aiSideTabOpen && aiResult.creatorId === userState.id) {
                actions.removeToasterAndResetToasterId(state.generatingToasterId);
                actions.updateAiResult({ id: aiResult.id, name: aiResult.name, isReadByCreator: true });
            }

            state.result = aiResult;
            state.prompType = aiResult.promptType;
            state.customPrompt = aiResult.customPrompt || '';

            const aiFilters = Object.values(aiResult.filters)[0].aiFilters;

            const entityFilters = aiFilters.filter(f => [FilterTypeEnum.label, FilterTypeEnum.customLabel].includes(f.type));

            if (entityFilters.length > 0) {
                const topics = entityFilters.reduce(
                    (t, f) => t.concat((f.value as Array<any>).map(v => ({ id: v, type: f.type === FilterTypeEnum.customLabel ? 'customLabel' : 'label' }))),
                    [] as Array<Topic>
                );

                state.topics = topics;
            }

            const primaryMetricQuestionFilter = aiFilters.find(f => [
                FilterTypeEnum.ces5Segment,
                FilterTypeEnum.ces7Segment,
                FilterTypeEnum.csatSegment,
                FilterTypeEnum.npsSegment
            ].includes(f.type));

            if (primaryMetricQuestionFilter ) {
                state.primaryMetricQuestion = commonState.dataset.dimension_definitions.find(d => d.id === primaryMetricQuestionFilter.dimensionId);
                state.metric = primaryMetricQuestionFilter.value![0];
            }

            return aiResult;
        },
        stopGetAiResult: () => {
            controller.abort();
        },
        getAiResults: async (offset: number|null = null): Promise<Array<AiResult>> => {
            controller = new AbortController();

            const limit = 25;
            let aiResults: Array<AiResult> = [];

            // Handle polling (fetching the most recent results)
            if (offset === null) {
                // Fetch the latest results starting from offset 0
                aiResults = await aiApi.getAiResults(commonState.dataset.id, state.resultSearch, limit, 0, controller.signal);
                state.results = [...aiResults, ...state.results.filter(r => !aiResults.find(aiR => aiR.id === r.id))]; // Replace existing results with fresh results from polling
            } else {
                if (limit > offset) {
                    // All results retrieved, no need to fetch more
                    return state.results;
                }
                // Handle pagination (scrolling for older results)
                if (offset >= state.results.length) {
                    aiResults = await aiApi.getAiResults(commonState.dataset.id, state.resultSearch, limit, offset, controller.signal);
                    state.results = [...state.results, ...aiResults]; // Append new results to the existing ones
                } else {
                    // All results retrieved, no need to fetch more
                    return state.results;
                }
            }

            return state.results;
        },
        updateAiResult: async ({ id, name, isReadByCreator }): Promise<void> => {
            await aiApi.updateAiResult({
                aiResultId: id,
                name: name,
                isReadByCreator,
            });

            if (state.result) {
                state.result = {
                    ...state.result!,
                    name,
                    isReadByCreator,
                };
            }
        },
        startPollingAiResults: async () => {
            if (state.polling || !isAiSummaryEnabled.value) {
                return;
            }

            do {
                await actions.getAiResults();

                const resultGenerated = state.results.find(r =>
                    r.creatorId === userState.id
                    && r.dimensionId === state.columnId
                    && r.status === AiResultStatus.GENERATING
                );

                if (resultGenerated) {
                    if (!state.generatingToasterId) {
                        let message;

                        if (resultGenerated.promptType === PromptType.COMPARE_CONTEXTS) {
                            message = i18next.t('AI_INSIGHT.TOASTER.COMPARISON_IN_PROGRESS', 'Your AI Comparison is in progress');
                        }
                        else {
                            message = i18next.t('AI_INSIGHT.TOASTER.IN_PROGRESS', 'Your AI Summary is in progress');
                        }

                        const id = toaster.loading(message, {
                            timeout: 0,
                            closable: true
                        });

                        state.generatingToasterId = id;
                    }
                    state.polling = true;
                    const { promise } = wait(3);
                    await promise;
                }
                else {
                    state.polling = false;
                }
            }
            while (state.polling);

            const unread = state.results.find(r =>
                r.creatorId === userState.id
                && r.dimensionId === state.columnId
                && r.status !== AiResultStatus.GENERATING
                && r.isReadByCreator === false
            );

            if (unread) {
                actions.removeToasterAndResetToasterId(state.generatingToasterId);

                if (unread.status === AiResultStatus.FAILED) {
                    state.generatingToasterId = toaster.error(i18next.t('AI_INSIGHT.TOASTER.FAILED', 'Your AI Summary failed to generate'), {
                        closable: true,
                        callback: async () => {
                            actions.setShouldRegenerate(true);
                            await actions.getAiResult(unread.id, true);
                            await actions.updateAiResult({ id: unread.id, name: unread.name, isReadByCreator: true });
                        },
                        callbackText: i18next.t('AI_INSIGHT.REGENERATE', 'Regenerate'),
                        timeout: 0
                    });
                }
                else {
                    if (state.aiSideTabOpen && state.result?.id === unread.id && !state.historyView) {
                        await actions.updateAiResult({ id: unread.id, name: unread.name, isReadByCreator: true });
                    } else {
                        let message;

                        if (unread.promptType === PromptType.COMPARE_CONTEXTS) {
                            message = i18next.t('AI_INSIGHT.TOASTER.COMPARISON_READY', 'Your AI Comparison is ready');
                        }
                        else {
                            message = i18next.t('AI_INSIGHT.TOASTER.READY', 'Your AI Summary is ready');
                        }

                        state.generatingToasterId = toaster.success(message, {
                            closable: true,
                            callback: async () => {
                                await actions.getAiResult(unread.id, true);
                                await actions.updateAiResult({ id: unread.id, name: unread.name, isReadByCreator: true });
                            },
                            callbackText: i18next.t('GLOBAL.OPEN', 'Open'),
                            timeout: 0
                        });
                    }
                }
            }
        },
        stopPollingAiResults: () => {
            state.polling = false;
            actions.removeToasterAndResetToasterId(state.generatingToasterId);
            controller.abort();
        },
        setHistoryView(val: boolean) {
            state.historyView = val;

            if (val) {
                state.result = null;
                state.prompType = null;
                state.metric = '';
                state.timeframe = '';
                state.topics = [];
                state.customPrompt = '';
            }
        },
        async setResultSearch(search) {
            state.resultSearch  = search;
            await actions.startPollingAiResults();
        },
        async exportAiResult(id: number, name: string): Promise<void> {
            const response = await aiApi.exportAiResult(id);
            saveAs(response, name + '.xlsx');
        },
        async deleteAiResult(id: number): Promise<void> {
            await aiApi.deleteAiResult(id);
            state.results = state.results.filter(r => r.id !== id);
        },
        resetAiState: (isFullReset: boolean = false): void => {
            // if it is not a full reset that it means only the sidebar component is closed, not the entire dashboard,
            // in this case don't delete results, polling and generatingToasterId
            if (isFullReset) {
                actions.removeToasterAndResetToasterId(state.generatingToasterId);
                Object.entries(getInitialState()).forEach(([key,value]) => state[key] = value);
            } else {
                Object.entries(getInitialState()).forEach(([key,value]) => {
                    if (!['results', 'polling', 'generatingToasterId'].includes(key)) {
                        state[key] = value;
                    }
                });
            }
        },
        removeToasterAndResetToasterId: (id: string) => {
            if (id) {
                removeToaster(id);
                state.generatingToasterId = '';
            }
        },
        async translateAiResult(result: string): Promise<string> {
            return await aiApi.getAiResultTranslation({
                text: result,
                targetLanguageId: commonState.dataset.language_id,
                sourceLanguageId: languages.ENGLISH // Ai results are always English
            });
        },
        setCustomPrompt(prompt: string): void {
            state.customPrompt = prompt;
        },
        setShouldRegenerate(shouldRegenerate: boolean): void {
            state.shouldRegenerate = shouldRegenerate;
        }
    };

    return actions;
};


export const makeGetters = () => ({
    isAiSummaryEnabled
});
