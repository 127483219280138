import { RouteConfig } from 'vue-router';
import { datasetTypeId } from '@/domain/dataset/datasetTypeId';
import useCommonStore from '@/dashboard/common/store/commonStore';
import analyticsRoutes from './analytics/routes';
import overviewRoutes from './overview/routes';
import textAnalysisRoutes from './textAnalysis/routes';

const routes: RouteConfig[] = [
    analyticsRoutes,
    overviewRoutes,
    textAnalysisRoutes,
    {
        path: ':datasetId/campaign-metrics',
        name: 'campaign-metrics',
        component: () => import('./campaign-metrics/CampaignMetrics.vue'),
    },
    {
        path: ':datasetId',
        name: 'fallback',
        beforeEnter: async (to, from, next) => {
            next({
                name: [datasetTypeId.excel, datasetTypeId.website_crawler, datasetTypeId.pdf_extractor].includes(useCommonStore().state.dataset.type) ? 'textAnalysis' : 'overview',
                params: { datasetId: to.params.datasetId },
                query: useCommonStore().state.query
            });
        }
    },
];

export default routes;
