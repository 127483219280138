import api from '@/api';
import dimensionTypeEnum from '../dimensionTypeEnum';

/**
 * @param firstRow String[]
 * @returns boolean
 */
export const isFirstRowHeader = (firstRow: string[]): boolean => {
    return firstRow.every(cell => getDimensionTypeFromText(cell) === dimensionTypeEnum.DONT_USE);
};

/**
 * @param cells String[]
 * @return Number - dimensionTypeEnum value
 */
export const getDimensionTypeForColumn = (cells: string[], noVerbatimColumn = false): dimensionTypeEnum => {
    // If we find some reference to a type in the header, use that type
    const typeByHeader = recognizeTypeByHeader(cells[0]);
    if (typeByHeader !== null) {
        return typeByHeader;
    }

    // we dont need the first row anymore
    const cellsWithoutHeader = [...cells];
    cellsWithoutHeader.shift();

    // If every cell is empty, probably we should not put in the output excel
    const isAllEmpty = cellsWithoutHeader.every(cell => cell.length === 0);
    if (isAllEmpty) {
        return dimensionTypeEnum.DELETE;
    }

    const notEmptyCells = cellsWithoutHeader.filter(cell => cell.length > 0);

    // If almost every field (>90%) start with http:// or https://, its probably url
    const urlCells = notEmptyCells.filter(cell => cell.slice(0,7) === 'http://' || cell.slice(0,8) === 'https://');
    if (urlCells.length >= notEmptyCells.length * 0.9) {
        return dimensionTypeEnum.URL;
    }

    // If almost every field (>90%) is a number, its probably number
    const notNumberCells = notEmptyCells.filter(cell => isNaN(Number(cell)));
    if (notNumberCells.length < notEmptyCells.length * 0.1) {
        return dimensionTypeEnum.NUMBER;
    }


    // If almost every field (>90%) is a date, it's a date
    const notDateCells = notEmptyCells.filter(cell => getDimensionTypeFromText(cell) !== dimensionTypeEnum.DATE);
    if (notDateCells.length < notEmptyCells.length * 0.1) {
        return dimensionTypeEnum.DATE;
    }

    // If we got less than 10 repeating values, than its probably a category
    const uniqueValueSet = notEmptyCells.reduce((uniqueSet, cell) => {
        uniqueSet.add(cell);
        return uniqueSet;
    }, new Set());
    if (uniqueValueSet.size < notEmptyCells.length * 0.5) {
        return dimensionTypeEnum.CATEGORY;
    }

    if (!noVerbatimColumn) {
        // if the average length of the sample cells is more than 60 characters, we can analyze it
        const sumCellLength = cellsWithoutHeader.reduce((sum, cell) => sum + cell.length , 0);
        if (sumCellLength / cellsWithoutHeader.length > 60) {
            return dimensionTypeEnum.VERBATIM;
        }
    }

    // invidual marker
    if ((new Set(notEmptyCells)).size >= notEmptyCells.length * 0.9) {
        return dimensionTypeEnum.NON_UNIQUE_ID;
    }

    return dimensionTypeEnum.DONT_USE;
};

/**
 * If we didn't find a verbatim column, we assign the role to the column with the longest cell in the sample
 * @param columns String[][]
 * @returns Number - the index of the verbatim column
 */
export const verbatimColumnFallback = (columns: string[][]): number => {
    let longestColumnIndex = 0;
    let globalLongestColumnLength = 0;
    columns.forEach((column, index) => {
        const longestCellLength = column.reduce((acc, cell) => Math.max(cell.length, acc), 0);
        if (longestCellLength > globalLongestColumnLength) {
            longestColumnIndex = index;
            globalLongestColumnLength = longestCellLength;
        }
    });
    return longestColumnIndex;
};

/**
 * @param column String[]
 * @returns Number - language id
 */
export const recognizeColumnLanguage = async (column: string[]): Promise<number> => {
    const concatedText = column.reduce((concatedText, cell) => concatedText + ' . ' + cell, '').substring(0, 1000);
    const language = await api.recognizeLanguage(concatedText);
    return language.id;
};

/**
 *
 * @param headerText String
 * @return Number|null - dimensionTypeEnum value or null if no match
 */
function recognizeTypeByHeader(headerText: string): number|null {
    if (refersToId(headerText)) {
        return dimensionTypeEnum.ID;
    } else if (refersToDate(headerText)) {
        return dimensionTypeEnum.DATE;
    } else if (refersToCountry(headerText) || refersToGender(headerText)) {
        return dimensionTypeEnum.CATEGORY;
    } else if (refersToNPS(headerText)) {
        return dimensionTypeEnum.NPS;
    } else if (refersToCSAT(headerText)) {
        return dimensionTypeEnum.CSAT;
    } else if (refersToUrl(headerText)) {
        return dimensionTypeEnum.URL;
    }
    return null;
}

/**
 * @param text String
 * @returns Number - dimensionTypeEnum value
 */
function getDimensionTypeFromText(text: string): dimensionTypeEnum {
    if (!isNaN(Date.parse(text))) {
        return dimensionTypeEnum.DATE;
    } else if (!isNaN(parseInt(text))) {
        return dimensionTypeEnum.NUMBER;
    } else if (text.length > 128) {
        return dimensionTypeEnum.VERBATIM;
    }
    return dimensionTypeEnum.DONT_USE;
}

/**
 * @param text String
 * @returns boolean
 */
function refersToId(text: string): boolean {
    return !!(text.toLowerCase() === 'id' ||
        text.match('/\bid/i') ||
        text.match('/_id/i') ||
        text.toLowerCase() === 'azonosító' ||
        text.search('/\bazonosító/i') !== -1 ||
        text.search('/\bazonositó/i') !== -1 ||
        text.search('/\bazonosito/i') !== -1);
}

/**
 * @param text String
 * @returns boolean
 */
function refersToDate(text: string): boolean {
    return !!(text.toLowerCase().includes('date') || text.toLowerCase().includes('datum'));
}

/**
 * @param text String
 * @returns boolean
 */
function refersToGender(text: string): boolean {
    return !!(text.toLowerCase() === 'gender' || text.toLowerCase() === 'nem');
}

/**
 * @param text String
 * @returns boolean
 */
function refersToCountry(text: string): boolean {
    return !!(text.toLowerCase() === 'country' || text.toLowerCase() === 'orszag');
}


/**
 * @param text String
 * @returns boolean
 */
function refersToNPS(text: string): boolean {
    return !!(text.toLowerCase().includes('nps'));
}

/**
 * @param text String
 * @returns boolean
 */
function refersToCSAT(text: string): boolean {
    return !!(text.toLowerCase() === 'csat');
}

function refersToUrl(text: string): boolean {
    return text.toLowerCase().includes('url');
}
