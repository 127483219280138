<template>
    <div>
        <custom-label-popup
            v-if="canManageCustomLabels && popup.open"
            :title="popup.mode === 'edit' ? $t('KEYWORD.EDIT_TITLE') : $t('KEYWORD.ADD_TITLE')"
            :button-text="buttonText"
            :keyword-details="popup.customLabel"
            :manual-custom-label-create="manualCustomLabelCreate"
            :only-save="onlySave || popup.mode === 'edit'"
            @closePopupBar="closePopup"
            @keywordHasBeenAddedOrEdited="onSaveCustomLabel"
        />
    </div>
</template>

<script lang="ts">
import { computed, defineComponent, toRefs } from 'vue';
import CustomLabelPopup from './CustomLabelPopup.vue';

import useCustomLabelStore from '../../store/';

import { toaster } from '@/utils/toaster';
import useUserStore from '@/user/store';
import i18next from 'i18next';

export default defineComponent({
    components: {
        CustomLabelPopup
    },
    props: {
        manualCustomLabelCreate: { type: Boolean, default: false },
        onlySave: { type: Boolean, default: false },
    },
    emits: ['save-and-add'],
    setup(_, context) {
        const { state, actions } = useCustomLabelStore();
        const { canManageCustomLabels } = useUserStore().getters;

        const { popup } = toRefs(state);

        const { closePopup, saveCustomLabel } = actions;

        async function onSaveCustomLabel({ cl, add }) {
            try {
                const { mode, id } = await saveCustomLabel(cl);
                if (mode === 'edit') {
                    toaster.success(i18next.t('KEYWORD.UPDATE_SUCCESS'));
                } else {
                    toaster.success(i18next.t('KEYWORD.ADD_SUCCESS'));
                }
                if (add) {
                    context.emit('save-and-add', { ...cl, id });
                }
            } catch {
                toaster.error('Something went wrong while saving...');
            }
        }

        const buttonText = computed(() => {
            return popup.value.mode === 'edit' ? i18next.t('GLOBAL.SAVE') : i18next.t('KEYWORD.CREATE');
        });

        return {
            canManageCustomLabels, popup, closePopup, onSaveCustomLabel, buttonText
        };
    }
});
</script>
