const urlPattern = new RegExp(
    '^' +                                   // start of string
    '(https?:\\/\\/)?' +                    // optional protocol (http or https)
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' +  // domain name with optional subdomains
    '((\\d{1,3}\\.){3}\\d{1,3}))' +         // OR IP address (IPv4)
    '(\\:\\d+)?' +                          // optional port
    '(\\/[-a-zA-Z\\dА-ЯҐЄІЇа-яґєії%_.~+-@&=,;:’#|!*()\\\\[\\]$]*)*' +  // path (including Cyrillic characters and '/')
    '(\\?[;&a-zA-Z\\d%_.~+=-@,!*/:|]*)?' +  // optional query string
    '(\\#[-a-zA-Z\\d_]*)?' +                // optional fragment
    '$',                                    // end of string
    'i'                                     // case insensitive flag
);

export const isUrlColumnDataValid = (urlColumnData, isNullValueEnabled = false) => {
    if (isNullValueEnabled) {
        return urlColumnData.every(url => urlPattern.test(url) || !url);
    }
    return urlColumnData.every(url => urlPattern.test(url));
};
