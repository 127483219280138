import { Filter } from '@/dashboard/common/types/filterInterface';

export enum PromptType {
    SUMMARIZE = 'SUMMARIZE',
    PAIN_POINTS = 'PAIN_POINTS',
    LOVE_POINTS = 'LOVE_POINTS',
    COMPARE_CONTEXTS = 'COMPARE_CONTEXTS',
    FREETEXT_PROMPT = 'FREETEXT_PROMPT',
}

export enum MetricType {
    NPS = 'nps',
    CES = 'ces',
    CSAT = 'csat',
}

export interface Topic {
    id: number,
    type: string,
}

export interface AiResult {
    id: number,
    llmId: string,
    dimensionId: string,
    name: string,
    creatorId: number,
    creatorName: string,
    promptType: PromptType,
    filters: {
        [uuid: string]: {
            dashboardFilters: Array<Filter>,
            aiFilters: Array<Filter>
        }
    },
    sampleVerbatimCounts: { [uuid: string]: number },
    result: string,
    createdAt: string,
    status: AiResultStatus,
    isReadByCreator: boolean,
    customPrompt?: string,
    totalVerbatimCount?: { [uuid: string]: number },
    totalVerbatimLength?: { [uuid: string]: number },
    sampleVerbatimLength?: { [uuid: string]: number },
    samplingVariables?: string[],
}

export enum AiResultStatus {
    GENERATING = 'generating',
    FINISHED = 'finished',
    REGENERATED = 'regenerated',
    FAILED = 'failed',
}

export enum AiResultVariable {
    VERBATIM_LENGTH = 'verbatimLength',
    VERBATIM_SENTIMENT = 'verbatimSentiment',
    NPS_SEGMENT = 'npsSegment',
    CSAT_SEGMENT = 'csatSegment',
    CES_SEGMENT = 'cesSegment',
}
