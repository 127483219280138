import useUserStore from '@/user/store';
import { computed, reactive } from 'vue';
import i18next from 'i18next';
import * as uuid from 'uuid';
import { Filter } from '../types/filterInterface';

import useCommonStore from './commonStore';
import useFilterStore from '@/dashboard/common/store/filterStore';

const { state: commonState, actions: commonActions } = useCommonStore();
const { actions: filterActions } = useFilterStore();

export interface Dataseries {
    id?: string,
    title: string,
    color: string,
    filters: Filter[],
}

interface DataseriesState {
    selectedDataseries: string[],
    seriesIdForDrilldown: null|string
}

export const state = reactive<DataseriesState>({
    selectedDataseries: [],
    seriesIdForDrilldown: null
});

const dataseries = computed(() => commonState.dashboardSettings?.series || []);

export const getters = {
    dataseries,
    selectedDataseries: computed(() => {
        if (state.selectedDataseries.length === 0) {
            return [];
        }

        const series: Dataseries[] = [];

        if (state.selectedDataseries.includes('overall')) {
            series.push({
                id: 'overall',
                title: i18next.t('MULTIPLE_SERIES.OVERALL','Total data'),
                color: useUserStore().state.primaryColor || '#B000B5',
                filters: [],
            });
        }

        return series.concat(dataseries.value.filter(ds => state.selectedDataseries.includes(ds.id!)) as Dataseries[]);
    }),
    filterbarDisabled: computed(() => state.selectedDataseries.length > 0),
};

export const actions = {
    createDataseries: async (dataseries: Dataseries): Promise<string> => {
        const id = uuid.v1();

        const series = [...getters.dataseries.value, { id, ...dataseries }];

        await commonActions.setDashboardSettings({ series });

        return id;
    },
    editDataseries: async (dataseries: Dataseries): Promise<void> => {
        const series = [...getters.dataseries.value];

        const index = series.findIndex(ds => ds.id === dataseries.id);

        series[index] = {...dataseries};

        await commonActions.setDashboardSettings({ series });
    },
    toggleDataseries: (id: string): void => {
        filterActions.setFilters([]);
        if (state.selectedDataseries.includes(id)) {
            state.selectedDataseries = state.selectedDataseries.filter(ds => ds !== id);
        }
        else {
            state.selectedDataseries.push(id);

            if (state.selectedDataseries.length > 3) {
                state.selectedDataseries.shift();
            }
        }
    },
    deleteDataseries: async (id: string): Promise<void> => {
        const series = [...getters.dataseries.value].filter(ds => ds.id !== id);

        await commonActions.setDashboardSettings({ series });

        if (state.selectedDataseries.includes(id)) {
            state.selectedDataseries = state.selectedDataseries.filter(ds => ds !== id);
        }

        if (commonState.dashboardSettings?.series.length === 0) {
            state.selectedDataseries = [];
        }
    },
    reset: (): void => {
        state.selectedDataseries = [];
        try {
            sessionStorage.removeItem('selectedDataseries');
        } catch (e) {
            return;
        }
    },
    saveSelectedDataseries: (dataseries: string[]): void => {
        state.selectedDataseries = dataseries;
        try {
            sessionStorage.setItem('selectedDataseries', JSON.stringify(state.selectedDataseries));
        }
        catch (e) {
            return;
        }
    },
    reloadSelectedDataseries: (): Array<string> => {
        try {
            const savedSelectedDataseries = sessionStorage.getItem('selectedDataseries');

            if (savedSelectedDataseries) {
                const parsed = JSON.parse(savedSelectedDataseries);

                if (parsed.length > 0) {
                    state.selectedDataseries = parsed;
                }

                return parsed;
            }
            return [];
        } catch (e) {
            return [];
        }
    },
    setSeriesIdForDrilldown: (id): void => {
        state.seriesIdForDrilldown = id;
    }
};

export default function useDataseriesStore() {
    return {
        state,
        getters,
        actions,
    };
}
