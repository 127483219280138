<template>
    <scrollbar
        ref="tableScrollbar"
        class="bg-white rounded w-full shadow p-4"
        style="height: 50vh;"
        stop-propagation
    >
        <div
            v-if="isCsv"
            class="w-1/5 mb-3"
        >
            <delimiter-chooser
                @changed="$emit('delimiterChanged', $event)"
            />
        </div>
        <table class="w-full">
            <excel-preview-header
                v-if="isPreview"
                :column-types="columnTypes"
                :column-names="columnNames"
                :primary-column-type="primaryColumnType"
            />
            <thead>
                <excel-table-types
                    v-if="!appendExcel && possibleColumnTypes.length > 0"
                    :column-types="columnTypes"
                    :possible-column-types="possibleColumnTypes"
                    :primary-column-type="primaryColumnType"
                    :are-types-unique="areTypesUnique"
                    :default-type="defaultType"
                    :empty-columns="emptyColumns"
                    :column-data="dataColumns"
                    :is-verbatim-column-disabled="isVerbatimColumnDisabled"
                    :wrong-column-type-indexes="wrongColumnTypeIndexes"
                    @columnTypesChanged="$emit('columnTypesChanged', $event)"
                />
                <excel-table-languages
                    v-if="columnLanguages.length > 0"
                    :column-types="columnTypes"
                    :column-languages="columnLanguages"
                    :empty-columns="emptyColumns"
                    @columnLanguagesChanged="$emit('columnLanguagesChanged', $event)"
                />
                <excel-table-names
                    v-if="isColumnNameEditable"
                    :column-names="columnNames"
                    :primary-column-indexes="primaryColumnIndexes"
                    :columns-with-selectable-name="columnsWithSelectableName"
                    :column-name-options="columnNameOptions"
                    :empty-columns="emptyColumns"
                    :column-type="columnType"
                    @columnNamesChanged="$emit('columnNamesChanged', $event)"
                />
                <excel-table-config
                    v-if="appendExcel"
                    :config="config"
                    :original-columns="originalColumns"
                    :empty-columns="emptyColumns"
                    @config-changed="$emit('config-changed', $event)"
                />
            </thead>
            <draggable
                v-model="dragAndDropRows"
                tag="tbody"
                handle=".separator"
                :delay="50"
            >
                <component
                    :is="row[0] && row[0] === separatorSymbol ? 'excel-table-separator-row' : 'excel-table-data-row'"
                    v-for="(row, index) in dragAndDropRows"
                    :key="index"
                    :row="row"
                    :row-number="index"
                    :is-disabled="index <= header - 1 || isVerbatimColumnDisabled && row[0] && row[0] === separatorSymbol"
                    :is-first="index === header + 1"
                    :column-types="columnTypes"
                    :primary-column-indexes="primaryColumnIndexes"
                    :is-data-text-hidden="possibleColumnTypes.length === 0"
                    :empty-columns="emptyColumns"
                />
            </draggable>
        </table>
    </scrollbar>
</template>

<script lang="ts">
import { computed, defineComponent, ref, watch } from 'vue';
import Scrollbar from '@/components/ui/Scrollbar.vue';
import draggable from 'vuedraggable';
import ExcelTableTypes from '@/components/common/ExcelSettings/ExcelTableTypes.vue';
import ExcelTableNames from '@/components/common/ExcelSettings/ExcelTableNames.vue';
import ExcelTableDataRow from '@/components/common/ExcelSettings/ExcelTableDataRow.vue';
import ExcelTableSeparatorRow from '@/components/common/ExcelSettings/ExcelTableSeparatorRow.vue';
import ExcelTableConfig from './ExcelTableConfig.vue';
import ExcelTableLanguages from '@/components/common/ExcelSettings/ExcelTableLanguages.vue';
import ExcelPreviewHeader from './ExcelPreviewHeader.vue';
import DelimiterChooser from '@/components/common/ExcelSettings/DelimiterChooser.vue';

export default defineComponent({
    components: {
        Scrollbar,
        draggable,
        ExcelTableTypes,
        ExcelTableNames,
        ExcelTableDataRow,
        ExcelTableSeparatorRow,
        ExcelTableConfig,
        ExcelTableLanguages,
        ExcelPreviewHeader,
        DelimiterChooser
    },
    props: {
        dataColumns: { type: Array as () => Array<any>, default: () => ([]) },
        columnTypes: { type: Array as () => Array<any>, default: () => ([]) },
        columnNames: { type: Array, default: () => ([]) },
        header: { type: Number, default: 1 },
        possibleColumnTypes: { type: Array, default: () => [] },
        primaryColumnType: { type: [String, Number], default: null },
        columnsWithSelectableName: { type: Array, default: () => ([]) },
        isColumnNameEditable: { type: Boolean, default: false },
        columnNameOptions: { type: Array, default: () => ([]) },
        areTypesUnique: { type: Boolean, default: true },
        defaultType: { type: [String, Number], default: null },
        appendExcel: { type: Boolean, default: false },
        config: { type: Object, default: () => ({}) },
        originalColumns: { type: Array, default: () => ([]) },
        columnLanguages: { type: Array, default: () => ([]) },
        noHeaderSeparator: { type: Boolean, default: false },
        isPreview: { type: Boolean, default: false },
        emptyColumns: { type: Array as () => Array<number>, default: () => [] },
        columnType: { type: String, default: 'column' },
        isCsv: { type: Boolean, default: false },
        isVerbatimColumnDisabled: { type: Boolean, default: false },
        wrongColumnTypeIndexes: { type: Array as () => Array<number>, default: () => [] },
    },
    setup(props, context) {
        const separatorSymbol = Symbol('separator');

        const dragAndDropRows = computed({
            get() {
                const dndRows = props.dataColumns.reduce((rows, column) => {
                    column.slice(0, 10).forEach((cell, rowIndex) => {
                        if (!rows[rowIndex]) {
                            rows[rowIndex] = [];
                        }
                        rows[rowIndex].push(cell);
                    });
                    return rows;
                }, []);
                if (!props.noHeaderSeparator) {
                    dndRows.splice( props.header, 0, Array(props.dataColumns.length).fill(separatorSymbol));
                }
                return dndRows;
            },
            set(dndRows: any[]) {
                const newHeader = dndRows.reduce((headerRow, row, index) => row[0] === separatorSymbol ? index : headerRow, 0);
                if (newHeader !== props.header) {
                    context.emit('headerChanged', newHeader);
                }
            }
        });

        const primaryColumnIndexes = computed<number[]>(() => {
            if (props.primaryColumnType === null) {
                return [];
            }
            return props.columnTypes.reduce(
                (primaryIndexes, type, index) => type == props.primaryColumnType ? [...primaryIndexes, index] : primaryIndexes,
                []
            );
        });

        const tableScrollbar = ref();

        watch(props, () => {
            tableScrollbar.value?.redraw?.();
        });

        return {
            separatorSymbol,
            dragAndDropRows,
            primaryColumnIndexes,
            tableScrollbar,
        };
    },
});
</script>
