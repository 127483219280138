import debounce from 'lodash/debounce';
import * as api from '@/dashboard/api/datasetApi';
import { state as commonState } from '@/dashboard/common/store/commonStore';
import { DimensionDefinition } from '@/domain/dataset/DatasetInterface';
import getChartData from '@/dashboard/common/utils/getChartData';
import orderBy from 'lodash/orderBy';
import i18next from 'i18next';
import makeGetters from './getters';

import PQueue from 'p-queue';
const queue = new PQueue({ concurrency: 6 });

import useUserStore from '@/user/store';

export default function makeActions(state) {
    const getters = makeGetters();

    const load = debounce(async (datasetRows: number) => {
        state.isLoading = true;
        state.stats = [];
        state.answers = datasetRows;

        queue.clear();
        for (const dimension of getters.metaDataDimensions.value) {
            if (dimension.type === 6 || dimension.name === 'Filling time' || dimension.name === 'Submitted at') {
                queue.add(() => actions.getDistributionData(dimension));
            }
        }
        queue.add(() => actions.getFallOffData());
        await queue.onIdle();
        actions.prepareAnalytics();
        state.isLoading = false;
    }, 500);

    const getTitle = (name: string) => {
        switch (name) {
        case 'Fall off':
            return i18next.t('DASHBOARD.FILLER_STATS.FALL_OFF', 'Fall-off chart');
        case 'Filling time':
            return i18next.t('DASHBOARD.FILLER_STATS.FILLING_TIME_DISTRIBUTION');
        case 'Completed':
            return i18next.t('DASHBOARD.FILLER_STATS.COMPLETED', 'Completed');
        case 'Browser':
            return i18next.t('DASHBOARD.FILLER_STATS.BROWSER');
        case 'Platform':
            return i18next.t('DASHBOARD.FILLER_STATS.PLATFORM');
        case 'Device Type':
            return i18next.t('DASHBOARD.FILLER_STATS.DEVICE');
        case 'Country':
            return i18next.t('DASHBOARD.METADATA_COUNTRY', 'Country');
        default:
            return name;
        }
    };

    const getOrder = (name: string) => {
        switch (name) {
        case 'Fall off':
            // when ordering this will always be the last
            return Infinity;
        case 'Filling time':
            return 2;
        case 'Completed':
            return 3;
        case 'Browser':
            return 4;
        case 'Platform':
            return 5;
        case 'Device Type':
            return 6;
        case 'Country':
            return 7;
        default:
            return 13;
        }
    };

    const getChartType = (name: string) => {
        switch (name) {
        case 'Fall off':
            return 'bar';
        case 'Filling time':
            return 'column';
        default:
            return 'pie';
        }
    };

    const actions = {
        load,
        async getDistributionData(dimension: DimensionDefinition) {
            const { dictionary, type } = dimension;
            const response = await api.getDimensionDistribution(commonState.dataset.id, dimension.id, []);
            const chartData = getChartData(response, dictionary, type)
                .filter(cd => cd.id !== null && cd.value !== 0)
                .sort((cd1, cd2) => parseInt(cd1.id) - parseInt(cd2.id));

            state.stats.push({
                id: dimension.id,
                name: dimension.name,
                type: dimension.type,
                data: chartData
            });
        },
        async getFallOffData() {
            const response = await api.getResponseCounts(commonState.dataset.id);
            const orderedQuestions = orderBy(response.map((dimension) => {
                const question = getters.questions.value.find(q => q.id === dimension.id);
                return {
                    ...dimension,
                    name: question?.name,
                    order: question?.order
                };
            }).filter(d => !!d.order), ['order']);
            state.stats.push({
                id: 'fall-off-chart',
                name: 'Fall off',
                type: 10,
                data: orderedQuestions
            });
        },
        prepareAnalytics() {
            state.completedDimension = state.stats.find(s => s.name === 'Completed') || null;
            state.avgFillingDimension = state.stats.find(s => s.name === 'Filling time') || null;
            state.submittedAtDimension = state.stats.filter(s => s.name === 'Submitted at').map((dimension) => {
                return {
                    ...dimension,
                    values: dimension.data,
                    color: useUserStore().state.primaryColor
                };
            })[0] || null;
            state.furtherCharts = orderBy(state.stats
                .filter(dimension => dimension?.name !== 'Submitted at')
                .map((dimension) => ({
                    ...dimension,
                    values: dimension.data,
                    title: getTitle(dimension.name),
                    order: getOrder(dimension.name),
                    chart: getChartType(dimension.name),
                    color: useUserStore().state.primaryColor
                })),
            ['order']);
        },

        reset() {
            state.isLoading = true;
            state.answers = 0;
            state.stats = [];
            state.completedDimension = null;
            state.submittedAtDimension = null;
            state.avgFillingDimension = null;
            state.furtherCharts = [];
        }
    };

    return actions;
}
