import store from '@/store';
import useUserStore from '@/user/store';

const { state: user } = useUserStore();

export const isFeatureEnabled = (name) => {
    let accessedFeatures = [];
    if (Array.isArray(user.accessedFeatures) && user.accessedFeatures.length !== 0) {
        accessedFeatures = user.accessedFeatures;
    } else {
        accessedFeatures = store.state.survey.accessedFeatures;
    }
    return Array.isArray(accessedFeatures) ? accessedFeatures.includes(name) : false;
};

export const isCampaignApiEnabled = () => isFeatureEnabled('campaign-api');
export const isAISummaryEnabled = () => isFeatureEnabled('ai-summary');
