import { toRaw } from 'vue';
import debounce from 'lodash/debounce';
import { state as commonState } from '@/dashboard/common/store/commonStore';
import { getFilters } from '@/dashboard/common/store/filterStore';
import * as datasetApi from '@/dashboard/api/datasetApi';
import { TextUnit } from '@/dashboard/common/types/commonInterface';
import { Filter } from '@/dashboard/common/types/filterInterface';
import { saveAs } from 'file-saver';
import { produce } from 'immer';
import { CustomLabel } from '@/custom-labels/store/types';

const paginationLimit = 25;

const getInitialState = () => ({
    isFeedLoading: false,
    hasFeedReachedEnd: false,
    textUnits: [] as TextUnit[],
    feedSearchQuery: '',
    feedSort: 'semantic_analysis_id:asc',
    newManuallyAddedCustomLabels: [] as CustomLabel[],
});

export const initialState = getInitialState();

export const makeActions = (state) => {
    const getTextUnits = async () => {
        state.isFeedLoading = true;
        state.textUnits = [];
        state.textUnits = await datasetApi.getTextUnits(
            commonState.dataset.id,
            state.columnId,
            state.feedSearchQuery,
            state.feedSort.split(':')[0],
            state.feedSort.split(':')[1],
            paginationLimit,
            null,
            null,
            getFilters()
        );
        state.isFeedLoading = false;
        state.hasFeedReachedEnd = state.textUnits.length < paginationLimit;
    };
    return {
        getTextUnits,
        addTranslationToTextUnits(semanticAnalysisId: number, translation: string) {
            state.textUnits = state.textUnits.map((tu) => tu.semantic_analysis_id === semanticAnalysisId ? { ...tu, translation } : tu);
        },
        async exportPosts() {
            const dataSourceName = commonState.dataset.name.split('.xlsx')[0].split('.xls')[0];
            const fileName = `${dataSourceName}_feed_${(new Date()).toISOString().split('T')[0]}.xlsx`;
            const response = await datasetApi.exportTextUnits(
                commonState.dataset.id,
                state.columnId!,
                state.feedSearchQuery,
                getFilters() as Filter[],
            );
            saveAs(response, fileName);
        },
        setFeedSearchQuery: debounce(async (searchQuery ) => {
            state.feedSearchQuery = searchQuery;
            await getTextUnits();
        }, 500),
        async setFeedSort(sort) {
            state.feedSort = sort;
            await getTextUnits();
        },
        getMoreTextUnits: debounce(async () => {
            if (state.textUnits.length >= state.filteredTextUnitCount || state.hasFeedReachedEnd || state.isFeedLoading ) {
                return;
            }
            if (state.textUnits.length > 0) {
                state.isFeedLoading = true;
                if (!commonState.dataset.id || !state.columnId) {
                    Sentry.addBreadcrumb({
                        message: 'wrong parameters for getTextUnits',
                        data: {
                            datasetId: commonState.dataset.id,
                            columnId: state.columnId
                        }
                    });
                    throw new Error('wrong params');
                }
                
                // we have filters (e.g. label polarity and free text search) that cannot be handled within sql, so classic offset-limit doesn't work
                // in order to make sure the next page starts off where it should be backend needs to know the last textunit's id and oi (due to sorting)
                const lastTextUnit = state.textUnits[state.textUnits.length-1];
                const response = await datasetApi.getTextUnits(
                    commonState.dataset.id,
                    state.columnId,
                    state.feedSearchQuery,
                    state.feedSort.split(':')[0],
                    state.feedSort.split(':')[1],
                    paginationLimit,
                    lastTextUnit.semantic_analysis_id,
                    lastTextUnit.opinion_index,
                    getFilters()
                );
                state.textUnits = [ ...state.textUnits, ...response ];
                if (response.length < paginationLimit) {
                    state.hasFeedReachedEnd = true;
                }
                state.isFeedLoading = false;
            }
        }, 200),
        addNewManualCustomLabel(cl: CustomLabel) {
            state.newManuallyAddedCustomLabels.push(cl);
        },
        resetNewManuallyAddedCustomLabels: () => state.newManuallyAddedCustomLabels = [],
        removeLabelFromTextUnit: ({ textUnitId, entityId, entitySubtype }: { textUnitId: string, entityId: number, entitySubtype: string }): void => {
            state.textUnits = produce(state.textUnits, (draft) => {
                const textUnit = draft.find(tu => tu.semantic_analysis_id === textUnitId);

                textUnit.entities = textUnit.entities.filter(e => e.id !== entityId || e.subtype !== entitySubtype);
            });
        },
        changeLabelOIOnTextUnit: ({ textUnitId, entityId, entitySubtype, value }: { textUnitId: string, entityId: number, entitySubtype: string, value: number }): void => {
            state.textUnits = state.textUnits.map(tu => {
                if (tu.semantic_analysis_id !== textUnitId) return tu;

                const newEntities = tu.entities.map(e => {
                    if (e.subtype !== entitySubtype || e.id !== entityId) return e;

                    return {
                        ...e,
                        opinionIndex: value
                    };
                });

                return {
                    ...tu,
                    entities: newEntities
                };
            });
        },
        addManualCustomLabelsToTextUnit: ({ textUnitId, customLabels }: { textUnitId: string, customLabels: Array<{ id: number, opinionIndex: number }> }): void => {
            state.textUnits = produce(toRaw(state).textUnits, (draft) => {
                const textUnit = draft.find(tu => tu.semantic_analysis_id === textUnitId);

                if (!textUnit) return;

                // Based on Sentry this could be undefined which could cause push to fail
                textUnit.entities = textUnit.entities || [];

                customLabels.forEach(cl => {
                    if (textUnit.entities.some(e => e.id === cl.id && e.type === 'custom-label')) return;

                    textUnit.entities = textUnit.entities.concat({
                        id: cl.id,
                        mentionNumber: 0,
                        negMentionNumber: 0,
                        posMentionNumber: 0,
                        opinionIndex: cl.opinionIndex,
                        type: 'custom-label',
                        subtype: 'manual_custom_label',
                    });
                });
            });
        },
        resetFeedState: () => {
            Object.entries(getInitialState()).forEach(([key, value]) => state[key] = value);
        },
    };
};
