import DimensionTypeEnum from '@/domain/dimensionTypeEnum.js';
import { isUrlColumnDataValid } from '@/utils/urlColumnValidation';

function parseNumber(number: string|null): number {
    return number === null ? 0 : +number.replace(',', '.');
}

function isNumber(data: string|null): boolean {
    return !Number.isNaN(parseNumber(data));
}

export default function(columnData: Array<string>, columnType: DimensionTypeEnum ): boolean {
    switch (columnType) {
    case DimensionTypeEnum.URL:
        return isUrlColumnDataValid(columnData);
    case DimensionTypeEnum.DATE:
        return columnData.every(data => !isNaN(Date.parse(data)) || isNumber(data));
    case DimensionTypeEnum.NUMBER:
        return columnData.every(data => isNumber(data));
    case DimensionTypeEnum.NPS:
        return columnData.every(data => isNumber(data) && parseNumber(data) >= 0 && parseNumber(data) <= 10);
    case DimensionTypeEnum.CSAT:
    case DimensionTypeEnum.CES_5_SCALE:
        return columnData.every(data => isNumber(data) && parseNumber(data) >= 1 && parseNumber(data) <= 5);
    case DimensionTypeEnum.CES_7_SCALE:
        return columnData.every(data => isNumber(data) && parseNumber(data) >= 1 && parseNumber(data) <= 7);
    case DimensionTypeEnum.ID:
        return columnData.length === new Set(columnData).size;
    case DimensionTypeEnum.VERBATIM:
    case DimensionTypeEnum.CATEGORY:
    case DimensionTypeEnum.DONT_USE:
    case DimensionTypeEnum.NON_UNIQUE_ID:
    default:
        return true;
    }
}
