<template>
    <scrollbar
        class="results absolute w-full rounded bg-white text-black shadow-depth-300 z-50 max-h-96 py-2"
        stop-propagation
    >
        <!-- custom label tag hits -->
        <div
            v-for="tag in tagResults"
            :key="tag"
            class="whitespace-no-wrap truncate px-4 py-2 hover:bg-neutral-300 cursor-pointer"
            @click="handleAddFilter(tag, 'tag')"
        >
            {{ tag }} <span class="text-neutral-800">- {{ $t('ANALYSIS_CL_SEARCH_CL_TAG', 'Custom label tag') }}</span>
        </div>
        <div
            v-for="category in categoryResults"
            :key="category.id"
            class="whitespace-no-wrap truncate px-4 py-2 hover:bg-neutral-300 cursor-pointer"
            @click="handleAddFilter(category.name, 'category')"
        >
            {{ category.name }} <span class="text-neutral-800">- {{ $t('ANALYSIS_CL_SEARCH_CL_CATEGORY', 'Custom label category') }}</span>
        </div>
        <div
            v-for="creator in creatorResults"
            :key="creator"
            class="whitespace-no-wrap truncate px-4 py-2 hover:bg-neutral-300 cursor-pointer"
            @click="handleAddFilter(creator, 'creator')"
        >
            {{ creator }} <span class="text-neutral-800">- {{ $t('ANALYSIS_CL_SEARCH_CL_CREATOR', 'Custom label creator') }}</span>
        </div>
        <!-- ¨custom label hits -->
        <v-popover
            v-for="label in customLabelResults"
            :key="label.id"
            trigger="hover"
            placement="top"
            :disabled="label.tags.length === 0"
            class="brainfuck"
        >
            <!-- don't ask why -->
            <div
                class="whitespace-no-wrap truncate px-4 py-2 hover:bg-neutral-300 cursor-pointer"
                @click="handleAddLabel(label.id)"
            >
                {{ label.name }} <span class="text-neutral-800">- {{ $t('HOME.KEYWORDS', 'Custom labels') }}</span>
            </div>
            <template slot="popover">
                <fa-icon :icon="['fal', 'hashtag']" /> {{ label.tags.join(', ') }}
            </template>
        </v-popover>
        <div
            v-if="tagResults.length === 0 && categoryResults.length === 0 && customLabelResults.length === 0 && creatorResults.length === 0"
            class="px-4 py-2"
        >
            {{ $t('GLOBAL.NO_RESULTS', 'No match') }}
        </div>
    </scrollbar>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue';

import Scrollbar from '@/components/ui/Scrollbar.vue';
import { FontAwesomeIcon } from '@/utils/icons';

import useCustomLabelStore from '@/custom-labels/store/';
import { CustomLabel } from '../../store/types';

export default defineComponent({
    components: {
        Scrollbar,
        'fa-icon': FontAwesomeIcon,
    },
    props: {
        query: {type: String, required: true},
        selectedLabels: {type: Array, required: true},
        availableLabels: { type: Array as () => Array<CustomLabel>, required: false, default: null },
    },
    setup(props, context) {
        const { state, getters } = useCustomLabelStore();

        const selectableLabels = computed(() => props.availableLabels || state.customLabels);

        const customLabelResults = computed(() => selectableLabels.value.filter(label => {
            if (props.selectedLabels.find((selectedLabel: any) => selectedLabel.id === label.id)) { // already added
                return false;
            }
            if (label.name.toLowerCase().includes(props.query.toLowerCase())) {
                return true;
            }

            return false;
        }));

        const creatorResults = computed(() =>
            selectableLabels.value
                .map(label => typeof label.creator === 'string' ? label.creator : label.creator.full_name )
                .filter((creator, index) => selectableLabels.value.map(label => label.creator).indexOf(creator) === index)
                .filter(creator => creator.toLowerCase().includes(props.query.toLowerCase()))
        );

        const selectableLabelTags = computed(() => selectableLabels.value.map(cl => cl.tags).flat());
        const tagResults = computed(() => getters.allTags.value
            .filter(tag => tag.toLowerCase().includes(props.query.toLowerCase()))
            .filter(tag => selectableLabelTags.value.includes(tag))
        );

        const categoryResults = computed(() => state.categories
            .filter(category => category.name.toLowerCase().includes(props.query.toLowerCase()))
            .filter(category => !getters.categoriesWithNoCustomLabels.value.includes(category.id)) // filter out categories with no custom labels
            .filter(category => selectableLabels.value.map(cl => cl.category_id).includes(category.id)) // filter out categories that don't belong to the available labels
        );

        function handleAddLabel(id: number) {
            context.emit('add-label', id);
        }

        function handleAddFilter(value: string, type: 'tag' | 'category' | 'creator') {
            context.emit('update-filter', { value, type });
        }

        return {
            customLabelResults,
            handleAddLabel,
            tagResults,
            handleAddFilter,
            categoryResults,
            creatorResults
        };
    }
});
</script>

<style>
    .brainfuck .trigger {
        display: block !important;
    }
</style>
