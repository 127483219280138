import storageFactory from '@/utils/storageFactory';

let storage;

try {
    storage = storageFactory(() => window.localStorage);
} catch (e) {
    storage = storageFactory(() => {});
}

export default storage;