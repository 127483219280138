import {CommonStoreState} from '@/dashboard/common/types/commonInterface';
import {checkIpAdresses, getDataset, getSources} from '@/common/api/datasetApi';
import * as datasetApi from '../../api/datasetApi';
import {dimensionTypeId} from '@/domain/dataset/dimensionTypeId';
import api from '@/api/';
import {hex2rgb} from '@/utils/color';
import Highcharts, {ColorType} from 'highcharts';
import {getPrimaryColor} from '@/utils/theme';
import useSubscriptionStore from '@/subscription/store';
import planFeatureTypeEnum from '@/domain/planFeatureTypeEnum';
import useUserStore from '@/user/store';
import storage from '@/utils/storage';
import dayjs from 'dayjs';
import {datasetTypeId} from '@/domain/dataset/datasetTypeId';
import {fetchCampaignsByDatasetId} from '@/campaign/api';

export default function makeActions(state: CommonStoreState) {
    const getUserGroupData = async () => {
        const userGroupData = await api.getUserGroupData();
        state.userGroupData = userGroupData[0];
        state.tableStorageInfo = userGroupData[1];
    };
    const getDashboardSettings = async () => {
        try {
            state.dashboardSettings = await datasetApi.getDashboardSettings(state.dataset.id);
        } catch (e) {
            Sentry.captureException(e);
        }
    };
    const getExcludedLabels = async () => {
        try {
            state.excludedEntities = await datasetApi.getExcludedLabels(state.dataset.id);
        } catch (e) {
            Sentry.captureException(e);
        }
    };
    const excludeLabel = async (labelId, labelName, datasetToExcludeIn) => {
        try {
            await datasetApi.excludeLabel(labelId, labelName, datasetToExcludeIn, state.dataset.id);
        } catch (e) {
            Sentry.captureException(e);
        }
    };
    const includeLabels = async (labelIds: number[]) => {
        await datasetApi.includeLabels(labelIds, state.dataset.id);
    };
    const checkIfAccessRequestIsSent = async () => {
        if (!useUserStore().state.isGuestUser && useSubscriptionStore().getters.canUseFeature.value(planFeatureTypeEnum.ACCESS_GROUP)) {
            const accessRequests = await api.getDatasetAccessRequests(state.dataset.id);
            state.accessRequestAlreadySent = !!accessRequests.find(ar => ar.userId == useUserStore().state.id);
        }
    };
    const logDashboardVisit = async (id: string) => {
        await datasetApi.logDashboardVisit(id);
        storage.setItem('dashboard-visit-' + id, dayjs().unix());
    };
    const setColorScale = (scale?: string[])  => {
        const colors = [] as ColorType[];
        if (scale) {
            scale.forEach(color => {
                const [r, g, b] = hex2rgb(color);
                colors.push(new Highcharts.Color(`rgba(${r}, ${g}, ${b}, 1)`).get());
            });
        } else {
            const primaryColor = getPrimaryColor() || '#1CC6BA';
            const [r, g, b] = hex2rgb(primaryColor);
            const colorNumber = 8;
            for (let i = 0; i < colorNumber; i += 1) {
                colors.push(new Highcharts.Color(`rgba(${r}, ${g}, ${b}, ${(colorNumber - i) / colorNumber})`).get());
            }
        }
        state.colorScale = colors;
    };
    const getDatasetById = async (id: string) => {
        state.dataset = await getDataset(id);
    };

    const getAllSources = async () => {
        state.allSources = await getSources();
    };

    const checkIps = async (id: string) => {
        return await checkIpAdresses(id);
    };

    const getExampleVerbatim = async (datasetId: string, dimensionId: string) => {
        return await datasetApi.getExampleVerbatim(datasetId, dimensionId);
    };

    const getDimensionDistributionCount = async (datasetId: string, dimensionId: string) => {
        const response = await datasetApi.getDimensionDistribution(datasetId, dimensionId, []);
        const uniqueValues = [...new Set(response.map((date) => date.id))];
        return uniqueValues.length;
    };

    const actions = {
        getDatasetById,
        getUserGroupData,
        getDashboardSettings,
        getExcludedLabels,
        includeLabels,
        excludeLabel,
        checkIfAccessRequestIsSent,
        logDashboardVisit,
        setColorScale,
        checkIps,
        getExampleVerbatim,
        getDimensionDistributionCount,
        async init(id: string, isPrintView: boolean = false, force = false, isDummyInit = false) {
            if (state.initialized && !force) {
                return;
            }
            state.isPrintView = isPrintView;
            await getDatasetById(id);

            await actions.getLabelDistributions(id);

            if(state.dataset.auto_translation) {
                this.setLabelTranslationEnabling(true);
            }
            await getDashboardSettings();

            if(state.dataset.type === datasetTypeId.nmi) {
                await getAllSources();
            }

            if(state.dataset.hasFilterableCampaigns) {
                state.campaigns = await fetchCampaignsByDatasetId(state.dataset.id, 'active');
            }

            if(!isDummyInit) {
                state.initialized = true;
                await getUserGroupData();
                await checkIfAccessRequestIsSent();
                setColorScale();

                //It only makes sense to get excluded labels for datasets with verbatim dimension(s) and topic recognition on
                if (state.dataset.topic_recognition && state.dataset.dimension_definitions.filter(d => d.type === dimensionTypeId.VERBATIM).length > 0) {
                    await getExcludedLabels();
                }

                const dashboardVisited = storage.getItem('dashboard-visit-' + id);
                if (!dashboardVisited || dayjs.unix(dashboardVisited).format('YYYY-MM-DD') !== dayjs().format('YYYY-MM-DD')) {
                    await logDashboardVisit(id);
                }
            }
        },
        async getLabelDistributions(id) {
            const labelDistributions = {};
            for (const dd of state.dataset.dimension_definitions.filter(d => d.type === dimensionTypeId.VERBATIM)) {
                let labelDistribution = {};
                try {
                    labelDistribution = await datasetApi.getLabelDistribution(id, dd.id);
                } catch (e) {
                    Sentry.captureException(e);
                }
                labelDistributions[dd.id] = labelDistribution;
            }
            state.labelDistributions = labelDistributions;
        },
        setLabelTranslationEnabling(enabled: boolean) {
            state.labelTranslationEnabled = enabled;
        },
        async setDashboardSettings(newSettings: any) {
            const dashboardSettings = {...state.dashboardSettings, ...newSettings};
            state.dashboardSettings = await datasetApi.setDashboardSettings(state.dataset.id, dashboardSettings);
        },
        async translateTextUnit({textToTranslate, dimensionId, semanticAnalysisId, language = null}) {
            try {
                return await api.dashboard.translateTextUnit({
                    textToTranslate,
                    sourceLanguageId: state.dataset.language_id,
                    recordLanguageNickname: language,
                    recordId: semanticAnalysisId,
                    dimensionId: dimensionId,
                    datasetId: state.dataset.id
                });
            } catch (e) {
                Sentry.captureException(e);
                throw e;
            }
        },
        setQuery(query) {
            state.query = query;
        },
        setShowCLQuickReanalysisInfoBox(value: boolean): void {
            state.showCLQuickReanalysisInfoBox = value;
        },
        reset() {
            state.initialized = false;
            state.dataset = {
                id: '',
                legacy_id: 0,
                type: 1,
                name: '',
                rows: 0,
                analyzed_rows: 0,
                language_id: 0,
                languages: [],
                lowercase: false,
                accent: false,
                topic_recognition: false,
                text_splitting: 1,
                user_group_id: 0,
                created_at: '',
                updated_at: '',
                dimension_definitions: [],
                permissionLevel: 0,
                last_analysis_at: null,
                is_analysis_running: false,
                auto_translation: false,
                auto_translation_with_html: false,
                inputTranslation: false,
                hasFilterableCampaigns: false,
                hasNewFeedStoring: false
            };
            state.labelTranslationEnabled = false;
            state.labelDistributions = {};
            state.userGroupData = {};
            state.tableStorageInfo = {};
            state.colorScale = [];
            state.dashboardSettings = null;
            state.excludedEntities = [];
            state.query = undefined;
            state.accessRequestAlreadySent = false;
            state.showCLQuickReanalysisInfoBox = true;
            state.allSources = [];
        }
    };

    return actions;
}
