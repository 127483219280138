<template>
    <component
        :is="Boolean(to) ? 'router-link' : 'button'"
        :to="to"
        class="ds text-default"
        :class="[backgroundColorClass, textColorClass, borderColorClass, { 'border': variant === 'outline' }]"
        :style="style"
        :disabled="disabled || loading"
        :type="type"
        @click="onClick"
        @mouseover="mouseOver"
        @mouseleave="mouseLeave"
    >
        <div
            :style="{ position: 'relative' }"
            class="button-label"
        >
            <span
                v-if="$slots.icon"
                class="icon"
                :style="{ visibility: loading && !disabled ? 'hidden' : 'visible' }"
            >
                <slot name="icon" />
            </span>
            <span
                :style="{ visibility: loading && !disabled ? 'hidden' : 'visible', marginLeft: $slots.icon && $slots.default ? '10px' : '0' }"
                class="min-w-4"
            >
                <slot />
            </span>
            <span
                v-if="loading && !disabled"
                class="loading w-4 h-4 mx-auto"
            >
                <loading-spinner
                    :primary="type === 'primary'"
                    :destructive="destructive"
                />
            </span>
        </div>
    </component>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from 'vue';
import LoadingSpinner from './LoadingSpinner.vue';
import { isHexDark } from '@/utils/color';
import useUserStore from '@/user/store';

export default defineComponent({
    components: {
        LoadingSpinner,
    },
    props: {
        variant: {
            type: String,
            default: 'primary',
            validator: (value: string) => ['primary', 'secondary', 'outline', 'ghost'].includes(value),
        },
        type: {
            type: String,
            default: 'button',
            validator: (value: string) => ['button', 'submit', 'reset'].includes(value),
        },
        destructive: { type: Boolean, default: false },
        disabled: { type: Boolean, default: false },
        loading: { type: Boolean, default: false },
        to: { type: String, default: null },
    },
    setup(props, context) {
        const { state } = useUserStore();

        const hovered = ref(false);

        const backgroundColorClass = computed(() => {
            if (props.variant === 'primary') {
                if (props.disabled) {
                    return 'bg-neutral-500';
                }
                if (hovered.value && !props.loading) {
                    return props.destructive ? 'bg-red-700' : 'bg-primary-800';
                }
                return props.destructive ? 'bg-red-500' : 'bg-primary-700';
            }

            if (props.variant === 'secondary') {
                return (hovered.value && !props.loading) ? 'bg-neutral-200' : 'bg-white';
            }

            return 'bg-transparent';
        });

        const textColorClass = computed(() => {
            if (props.disabled) {
                return 'text-neutral-700';
            }

            if (props.variant === 'primary') {
                return isHexDark(state.primaryColor) ? 'text-white' : 'text-black';
            }

            if (props.variant === 'secondary') {
                return props.destructive ? 'text-red-500' : 'text-primary-700';
            }
            // outline or ghost
            if (props.destructive) {
                return hovered.value && !props.loading ? 'text-red-700' : 'text-red-500';
            }
            return hovered.value && !props.loading ? 'text-primary-800' : 'text-primary-800';
        });

        const borderColorClass = computed(() => {
            if (props.variant !== 'outline') {
                return '';
            }

            let color = hovered.value ? 'border-primary-800' : 'border-primary-700';
            if (props.destructive) {
                color = hovered.value ? 'border-red-700' : 'border-red-500';
            }
            if (props.disabled) {
                color = 'border-neutral-700';
            }

            return color;
        });

        const padding = computed(() => {
            if (props.variant === 'outline') {
                return context.slots.default ? '5px 11px' : '5px 6.5px';
            }
            return context.slots.default ? '6px 12px' : '6px 7.5px';
        });

        const style = computed(() => ({
            padding: padding.value,
            borderRadius: '0.25rem',
            cursor: props.disabled || props.loading ? 'default' : 'pointer',
            boxSizing: 'border-box',
        }));

        function onClick(event) {
            context.emit('click', event);
        }

        function mouseOver() {
            hovered.value = true;
        }

        function mouseLeave() {
            hovered.value = false;
        }

        return {
            style,
            onClick,
            mouseOver,
            mouseLeave,
            backgroundColorClass,
            textColorClass,
            borderColorClass,
        };
    },
});
</script>

<style scoped>
    button.ds:focus {
        outline: 0;
        box-shadow: 0px 0px 0px 1.5px #0083DA;
    }

    .button-label {
        display: flex;
        align-items: center;
        min-width: 16px;
        min-height: 16px;
    }

    .icon {
        display: inline-flex;
        align-items: center;
        height: 19px;
        width: 16px;
    }

    .loading {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        inset: 0;
    }
</style>
