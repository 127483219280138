<template>
    <component
        :is="root"
        :text="title"
        text-class="text-sm"
        is-open-by-default
        :has-border-top="false"
        divider
        :collapse="collapseSection"
        :open="openSection"
        :class="{ 'mt-3': noGrouping }"
        @changed="$emit('accordion-changed', $event)"
    >
        <p
            v-if="!noGrouping"
            class="mb-2"
        >
            <z-button
                variant="secondary"
                @click="$emit('add-labels', customLabels.map(cl => cl.id))"
            >
                <div class="flex items-center space-x-2">
                    <icon icon="plus" />
                    <span>
                        {{ $t('GLOBAL.ADD_ALL', 'Add all') }}
                    </span>
                </div>
            </z-button>
        </p>
        <v-popover
            v-for="label in sliced"
            :key="label.id"
            trigger="hover"
            placement="top"
            :disabled="label.tags.length === 0"
            class="inline"
        >
            <z-tag
                addable
                class="mb-1"
                @click="$emit('add-labels', [label.id])"
            >
                {{ label.name + (label.category ? ` (${label.category})` : '') }}
            </z-tag>
            <template slot="popover">
                <Icon icon="hashtag" /> {{ label.tags.join(', ') }}
            </template>
        </v-popover>
        <span
            v-if="!showAll && customLabels.length > 10"
            class="cursor-pointer text-sm"
            data-test-id="show-more"
            @click="showAll = true"
        >
            +{{ customLabels.length - 10 }}
        </span>
    </component>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import Accordion from '@/components/ui/Accordion.vue';
import Icon from '@/components/ui/Icon.vue';

import { CustomLabel } from '../../store/types';

export default defineComponent({
    components: {
        Accordion,
        Icon,
    },
    props: {
        name: { type: String, required: true },
        customLabels: { type: Array as () => Array<CustomLabel>, required: true },
        noGrouping: { type: Boolean, default: false },
        collapseSection: { type: Boolean, default: false },
        openSection: { type: Boolean, default: false },
    },
    emits: ['add-labels', 'accordion-changed'],
    data() {
        return {
            showAll: false
        };
    },
    computed: {
        root() {
            return this.noGrouping ? 'div' : Accordion;
        },
        sliced() {
            return this.customLabels.slice(0, this.showAll ? undefined : 10);
        },
        title() {
            return `${this.name} (${this.customLabels.length})`;
        }
    },
});
</script>
