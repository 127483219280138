import { api } from '@/api';
import { AiResult, PromptType } from '../utils/aiTypes';
import { Filter } from '@/dashboard/common/types/filterInterface';

export async function getAiResults(datasetId: string, search: { type: string|null, value: string|number|null, label: string|null }, limit: number, offset: number, signal): Promise<Array<AiResult>> {
    let path = `/aiResults?datasetId=${datasetId}`;

    if (search.type === 'creator' && search.value) {
        path += `&creatorId=${search.value}`;
    }

    if (search.type === 'name' && search.value) {
        path += `&name=${search.value}`;
    }

    path += `&limit=${limit}&offset=${offset}`;

    const { data } = await api.get(path, { signal });

    return data;
}

export async function getAiResult(id: number): Promise<AiResult> {
    const { data } = await api.get(`/aiResults/${id}`);

    return data;
}

export interface GenerateAiResultPayload {
    datasetId: string,
    dimensionId: string,
    promptType: PromptType,
    customPrompt?: string,
    filters: Array<{
        dashboardFilters: Array<Filter>,
        aiFilters: Array<Filter>
    }>
}

export async function generateAiResult(payload: GenerateAiResultPayload): Promise<number> {
    const { data } = await api.post('/aiResults', payload);

    return data;
}

export interface RegenerateAiResultPayload {
    id: number,
    name: string,
    promptType: PromptType,
    customPrompt?: string,
    filters: Array<{
        dashboardFilters: Array<Filter>,
        aiFilters: Array<Filter>
    }>
}

export async function regenerateAiResult({ id, ...body }: RegenerateAiResultPayload): Promise<number> {
    const { data } = await api.post(`/aiResults/${id}/regenerate`, body);

    return data;
}

interface UpdateAiResultPayload {
    aiResultId,
    name,
    isReadByCreator,
}

export async function updateAiResult(payload: UpdateAiResultPayload): Promise<any> {
    const { data } = await api.put(`/aiResults/${payload.aiResultId}`, {
        name: payload.name,
        isReadByCreator: payload.isReadByCreator
    });

    return data;
}

export async function exportAiResult(id): Promise<any> {
    const { data } = await api.post(`/aiResults/${id}/export`, null, { responseType: 'blob' });

    return data;
}

export async function deleteAiResult(id): Promise<void> {
    await api.delete(`/aiResults/${id}`);
}

export interface TranslateAiResultPayload {
    text: string,
    sourceLanguageId: number,
    targetLanguageId: number
}

export async function getAiResultTranslation(payload: TranslateAiResultPayload): Promise<string> {
    const { data } = await api.post('/portal/dashboard/getTranslation', { ...payload });

    return data;
}
