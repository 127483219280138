export default (api) => {
    return {
        async syncSurvey(options) {
            await api.post(`/vue/surveyforms/save/${options.id}`, {
                title: options.title,
                description: options.description,
                working_title: options.working_title,
                thank_you_title: options.thank_you_title,
                thank_you_description: options.thank_you_description,
                primary_color: options.primary_color,
                text_color: options.text_color,
                background_color: options.background_color,
                title_font_name: options.title_font_name,
                title_font_weight: options.title_font_weight,
                title_font_size: options.title_font_size,
                question_font_name: options.question_font_name,
                question_font_weight: options.question_font_weight,
                question_font_size: options.question_font_size,
                answer_font_name: options.answer_font_name,
                answer_font_weight: options.answer_font_weight,
                answer_font_size: options.answer_font_size,
                layout_spacing: options.layout_spacing,
                language_id: options.language_id,
                accent: options.accent,
                lowercase: options.lowercase,
                text_splitting: options.textSplitting,
                auto_translation: options.autoTranslation,
                auto_translation_with_html: options.autoTranslationWithHtml,
                is_question_ordinal_number_visible: options.is_question_ordinal_number_visible,
                question_tag_type: options.question_tag_type,
                is_browser_tab_title_enabled: options.is_browser_tab_title_enabled,
                is_filling_time_visible: options.is_filling_time_visible,
                has_own_privacy_policy: options.has_own_privacy_policy,
                privacy_policy_title: options.privacy_policy_title,
                privacy_policy_url: options.privacy_policy_url,
                is_autopage_enabled: options.is_autopage_enabled,
                filling_time: options.filling_time,
                bg_image: options.bg_image,
                is_filling_progress_visible: options.is_filling_progress_visible,
                filling_progress_position: options.filling_progress_position,
                logo_settings: options.logo_settings,
                thank_you_redirect: options.thank_you_redirect,
            });
        },
        async saveQuestions(questions, surveyId) {
            await api.post(`/vue/surveyforms/saveQuestions/${surveyId}`, { questions });
        },
        async getElements(id) {
            const {data} = await api.post('/vue/getsurveydetails', { id });
            return data;
        },
        async getDisqualifications(id) {
            const {data} = await api.post(`/vue/surveyforms/getDisqualifications/${id}`);
            return data;
        },
        async saveDisqualifications({ id, disqualificationPages }) {
            const {data} = await api.post(`/vue/surveyforms/saveDisqualifications/${id}`, { pages: disqualificationPages});
            return data;
        },
        async getSemanticSettings(id) {
            const {data} = await api.post('/vue/getSemanticSettings', { id });
            return data;
        },
        async getMetaData(id) {
            const { data } = await api.post('/vue/getsurveydata', { id });
            return data;
        },
        async getSelectedCustomLabels(id) {
            const { data } = await api.get(`/vue/surveyforms/${id}/keywords`);
            return data;
        },
        async saveItemPicture({ file, guid, surveyId }) {
            const formData = new FormData();
            formData.append('file', file);
            formData.append('guid', guid);
            const { data } = await api.post(`/vue/saveitempicture/${surveyId}`, formData);
            return data;
        },
        async deleteApiPicture(guid, surveyId) {
            await api.post(`/vue/deleteitempicture/${surveyId}`, { guid });
        },
        async saveFinishPicture({ file, order, surveyId }) {
            const formData = new FormData();
            formData.append('file', file);
            formData.append('order', order);
            const { data } = await api.post(`/vue/savefinishpicture/${surveyId}`, formData);
            return data;
        },
        async deleteFinishPicture(order, surveyId) {
            await api.post(`/vue/deletefinishpicture/${surveyId}`, { order });
        },
        async savePictureChoiceOption({ file, guid, index }) {
            const formData = new FormData();
            formData.append('file', file);
            formData.append('guid', guid);
            formData.append('index', index);
            const { data } = await api.post('/vue/savepicturechoice', formData);
            return data;
        },
        async updateLogo({ id, source }) {
            const { data } = await api.post(`/vue/survey/${id}/logo`, { source });
            return data;
        },
        async deleteLogo(id) {
            const { data } = await api.delete(`/vue/survey/${id}/logo`);
            return data;
        },
        async changeStatus(id, status) {
            const { data } = await api.post(`/vue/changeStatus/${id}`, { status });
            return data;
        },
        async saveUniqueName(id, unique_name) {
            const { data } = await api.post(`/vue/saveUniqueName/${id}`, { unique_name });
            return data;
        },
        async updatePublishSettings({ id, isMultipleFillingOn, multiFillRestriction, isAnonymousOn, isAutosaveOn, responseLimit, password, multiFillingRestrictionType, single_response_limit_target, overall_response_limit_target, resumable_survey_filling_guid }) {
            await api.post(`/vue/savePublishSettings/${id}`, {
                is_multiple_on: isMultipleFillingOn,
                multiple_restriction: multiFillRestriction,
                is_anonymous_on: isAnonymousOn,
                is_autosave_on: isAutosaveOn,
                target_number: responseLimit,
                password: password,
                multifilling_restriction_type: multiFillingRestrictionType,
                single_response_limit_target,
                overall_response_limit_target,
                resumable_survey_filling_guid,
            });
        },
        async saveUrlParameters({ id, parameters }) {
            await api.post(`/vue/savesurveyparameters/${id}`, { parameters });
        },
        async getNotificationEmailSettings(id) {
            const { data } = await api.post('/vue/getregularemailsettings', { id });
            return data;
        },
        async setNotificationEmailSettings({ id, emailList, type }) {
            const { data } = await api.post('/vue/setregularemailsettings', {id, emailList, type});
            return data;
        },
        async generatePdf(surveyId) {
            const { data } = await api.get(`vue/generateSurveyPdf/${surveyId}`, { responseType: 'blob' });
            return data;
        },
        async downloadUploadedFile(fileName) {
            const { data } = await api({
                method: 'get',
                url: `vue/survey/downloadUploadedFile/${fileName}`,
                responseType: 'blob'
            });
            return data;
        },
        async resetSurvey(id) {
            const { data } = await api.post(`vue/survey/${id}/reset`);
            return data;
        },
        async saveBgImage(id, file) {
            const formData = new FormData();
            formData.append('file', file);
            const { data } = await api.post(`vue/survey/${id}/uploadBgImage`, formData);
            return data;
        },
        async deleteBgImage(id) {
            const { data } = await api.delete(`vue/survey/${id}/deleteBgImage`);
            return data;
        },
        async saveFavicon(id, file) {
            const formData = new FormData();
            formData.append('file', file);
            const { data } = await api.post(`vue/survey/${id}/uploadFavicon`, formData);
            return data;
        },
        async deleteFavicon(id) {
            await api.delete(`vue/survey/${id}/deleteFavicon`);
        },
        async saveThankYouLogo(id, file) {
            const formData = new FormData();
            formData.append('file', file);
            const { data } = await api.post(`vue/survey/${id}/saveThankYouLogo`, formData);
            return data;
        },
        async saveDisqualificationPageLogo(id, disqPageId, file) {
            const formData = new FormData();
            formData.append('file', file);
            const { data } = await api.post(`vue/survey/${id}/saveDisqualificationPageLogo/${disqPageId}`, formData);
            return data;
        },
        async saveTrackingIds(id, settings) {
            const { data } = await api.post(`vue/survey/${id}/setTrackingIds`, settings);
            return data;
        },
    };
};
