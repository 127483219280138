import {Filter} from '@/dashboard/common/types/filterInterface';
import {TextUnit} from '@/dashboard/common/types/commonInterface';

export interface DrillDownState {
    drillDownTextUnits: TextUnit[],
    isDrillDownShown: boolean,
    isDrillDownLoading: boolean,
    drillDownSearchQuery: string,
    drillDownSort: string,
    drillDownName: string,
    drillDownFilter: Filter[],
    drillDownType: null|DrilldownType,
    drillDownValue: any,
    drillDownPolarity: null|PolarityType,
    drillDownDimensionId: null|string|number,
    drillDownBatchNumber: number,
    isMoreDrillDownLoading: boolean,
    isDrillDownFinished: boolean,
    drillDownVerbatimId: null|string,
    isDrillDownWarningShown: boolean,
}

export type DrilldownType = 'label'|'customLabel'|'customLabelCategory'|'relation'|'driver'|'category'|'date'|'phrase'|'phraseRelation'|'rating'|'polarity'|'nonUniqueIdentifier';

export enum PolarityType {
    positive = 'positive',
    neutral = 'neutral',
    negative = 'negative',
}

export type DateRange = 'daily'|'monthly'|'weekly';
