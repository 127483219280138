<template>
    <div class="selected-labels">
        <h1
            v-if="selectedLabels.length !== 0"
            class="my-1 text-sm"
        >
            {{ $t('KEYWORD.SELECTED_CUSTOM_LABELS', 'Selected Custom Labels') }} ({{ selectedLabels.length }})
        </h1>
        <v-popover
            v-for="label in sortedSelectedLabels"
            :key="label.id"
            trigger="hover"
            placement="top"
            :disabled="label.tags.length === 0"
            class="inline"
        >
            <z-tag
                v-if="!withOpinionIndex"
                deletable
                :background-color="(checkLanguageIsOk(label) || noLanguageCheck) ? null : '#F06060'"
                class="mb-1"
                @click="handleClick(label.id)"
                @remove="handleRemove(label.id)"
            >
                {{ label.name + (label.category ? ` (${label.category})` : '') }}
            </z-tag>
            <TextUnitLabel
                v-else
                :entity="{
                    id: label.id,
                    name: label.name,
                    type: 'custom-label',
                    opinionIndex: label.opinionIndex,
                    engName: label.name,
                    mentionNumber: 0,
                    subtype: label.subtype
                }"
                no-drilldown
                not-solid-icon
                always-show-buttons
                :no-edit-popup="noEditPopup"
                @edit="handleClick(label.id)"
                @change-oi="$emit('change-oi', { id: $event.id, opinionIndex: $event.value })"
                @remove-label="handleRemove(label.id)"
            />
            <template slot="popover">
                <fa-icon :icon="['fal', 'hashtag']" /> {{ label.tags.join(', ') }}
            </template>
        </v-popover>
    </div>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue';
import { FontAwesomeIcon } from '@/utils/icons';

import useCustomLabelStore from '@/custom-labels/store/';
import { CustomLabel } from '@/custom-labels/store/types';
import languages from '@/domain/analysisLanguageEnum';
import { setLoading } from '@/utils/loading';

import TextUnitLabel from '@/dashboard/common/components/TextUnits/TextUnit/TextUnitLabel.vue';

export default defineComponent({
    components: {
        'fa-icon': FontAwesomeIcon,
        TextUnitLabel,
    },
    props: {
        selectedLabels: { type: Array as () => Array<CustomLabel & { opinionIndex: number, subtype?: string }>,  required: true },
        languageId: { type: Number, required: true },
        noLanguageCheck: { type: Boolean, default: false },
        withOpinionIndex: { type: Boolean, default: false },
        noEditPopup: { type: Boolean, default: true },
    },
    setup(props, context) {
        const { actions } = useCustomLabelStore();

        function handleRemove(id: number) {
            context.emit('remove-label', id);
        }

        async function handleClick(id: number) {
            setLoading(true);
            await actions.openPopup('edit', id);
            setLoading(false);
        }

        function checkLanguageIsOk(label: CustomLabel): boolean {
            if (props.languageId === languages.MIXED) {
                return true;
            }

            return label.languages.length === 0 || label.languages.includes(props.languageId);
        }

        // @ts-ignore - .toSorted() is a relatively new JavaScript method, older Node.js or browser versions, do not yet support it. slice() will create a shallow copy too
        const sortedSelectedLabels = computed(() => props.selectedLabels.slice().sort((a,b) => a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1));

        return {
            handleRemove,
            handleClick,
            checkLanguageIsOk,
            sortedSelectedLabels,
        };
    }
});
</script>
