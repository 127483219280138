<template>
    <div class="px-1">
        <div
            class="flex justify-between items-center select-none space-x-2"
            :class="{
                'border-neutral-400 border-t': hasBorderTop,
                'cursor-pointer': !hideIcon,
            }"
            @click="toggle()"
        >
            <div
                class="my-3"
                :class="textClass"
                v-html="(!isAccordionOpened && closedText) || text"
            />
            <hr
                v-if="divider"
                class="flex-grow border-neutral-400"
            >
            <template v-if="!hideIcon">
                <icon
                    v-if="!isAccordionOpened"
                    :icon="iconClosed"
                    class="text-neutral-800 text-sm"
                />
                <icon
                    v-if="isAccordionOpened"
                    :icon="iconOpened"
                    class="text-neutral-800 text-sm"
                />
            </template>
        </div>
        <slot v-if="isAccordionOpened" />
    </div>
</template>

<script lang="ts">
import Icon from '@/components/ui/Icon.vue';
import {defineComponent, ref, watch} from 'vue';

export default defineComponent({
    components: { Icon },
    props: {
        iconOpened: { type: String, default: 'chevron-up' },
        iconClosed: { type: String, default: 'chevron-down' },
        hasBorderTop: { type: Boolean, default: true },
        text: { type: String, required: true },
        closedText: { type: String, default: null },
        isOpenByDefault: { type: Boolean, default: false },
        hideIcon: { type: Boolean, default: false },
        textClass: { type: String, default: 'font-bold text-sm' },
        divider: { type: Boolean, default: false },
        open: { type: Boolean, default: false },
        collapse: { type: Boolean, default: false },
    },
    setup(props, context) {
        const isAccordionOpened = ref(props.isOpenByDefault);

        const toggle = (val?: boolean, force?: boolean): void => {
            if (props.hideIcon && !force) return;

            if (val !== undefined) {
                isAccordionOpened.value = val;
            }
            else {
                isAccordionOpened.value = !isAccordionOpened.value;
            }

            context.emit('changed', isAccordionOpened.value);
        };

        watch(props, ({ open, collapse }) => {
            if (collapse) {
                isAccordionOpened.value = false;
                context.emit('changed', isAccordionOpened.value);
            }
            if (open) {
                isAccordionOpened.value = true;
                context.emit('changed', isAccordionOpened.value);
            }
        });

        return { isAccordionOpened, toggle };
    }
});
</script>
