import { config, library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon as FontAwesomeIcons } from '@fortawesome/vue-fontawesome';

import { faAngleDown } from '@fortawesome/pro-light-svg-icons';
import { faAngleUp } from '@fortawesome/pro-light-svg-icons';
import { faChevronRight }  from '@fortawesome/pro-light-svg-icons';
import { faChevronLeft }  from '@fortawesome/pro-light-svg-icons';
import { faHome } from '@fortawesome/pro-light-svg-icons';
import { faTag } from '@fortawesome/pro-light-svg-icons';
import { faTags } from '@fortawesome/pro-light-svg-icons';
import { faCog } from '@fortawesome/pro-light-svg-icons';
import { faShield } from '@fortawesome/pro-light-svg-icons';
import { faShieldAlt } from '@fortawesome/pro-light-svg-icons';
import { faClock } from '@fortawesome/pro-light-svg-icons';
import { faAngleLeft } from '@fortawesome/pro-light-svg-icons';
import { faAngleRight } from '@fortawesome/pro-light-svg-icons';
import { faBullhorn } from '@fortawesome/pro-light-svg-icons';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { faTimes as faSolidTimes } from '@fortawesome/free-solid-svg-icons';
import { faQuestionCircle } from '@fortawesome/pro-light-svg-icons';
import { faQuestionSquare } from '@fortawesome/pro-light-svg-icons';
import { faPen } from '@fortawesome/pro-light-svg-icons';
import { faTrash } from '@fortawesome/pro-light-svg-icons';
import { faSearch } from '@fortawesome/pro-light-svg-icons';
import { faDownload } from '@fortawesome/pro-light-svg-icons';
import { faCaretCircleUp } from '@fortawesome/pro-solid-svg-icons';
import { faPowerOff } from '@fortawesome/pro-light-svg-icons';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import { faInfoCircle as faSolidInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { faSortUp } from '@fortawesome/free-solid-svg-icons';
import { faSortDown } from '@fortawesome/free-solid-svg-icons';
import { faChevronUp } from '@fortawesome/pro-light-svg-icons';
import { faChevronDown } from '@fortawesome/pro-light-svg-icons';
import { faSort } from '@fortawesome/pro-light-svg-icons';
import { faListOl } from '@fortawesome/pro-light-svg-icons';
import { faFileExcel } from '@fortawesome/pro-light-svg-icons';
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons';
import { faEllipsisH as faEllipsisHLight } from '@fortawesome/pro-light-svg-icons';
import { faExclamationCircle } from '@fortawesome/pro-light-svg-icons';
import { faEye } from '@fortawesome/pro-light-svg-icons';
import { faClone } from '@fortawesome/pro-light-svg-icons';
import { faUpload } from '@fortawesome/pro-light-svg-icons';
import { faPencil } from '@fortawesome/pro-light-svg-icons';
import { faCopy } from '@fortawesome/pro-light-svg-icons';
import { faSearchPlus } from '@fortawesome/pro-light-svg-icons';
import { faSearchMinus } from '@fortawesome/pro-light-svg-icons';
import { faExclamationTriangle } from '@fortawesome/pro-light-svg-icons';
import { faFacebookF, } from '@fortawesome/free-brands-svg-icons';
import { faLinkedinIn } from '@fortawesome/free-brands-svg-icons';
import { faPlusCircle, } from '@fortawesome/pro-light-svg-icons';
import { faPlusCircle as faPlusSolidCircle } from '@fortawesome/pro-solid-svg-icons';
import { faLightbulb } from '@fortawesome/pro-light-svg-icons';
import { faChevronDoubleDown } from '@fortawesome/pro-light-svg-icons';
import { faChevronDoubleUp } from '@fortawesome/pro-light-svg-icons';
import { faTimesCircle } from '@fortawesome/pro-light-svg-icons';
import { faTimesCircle as faTimesSolidCircle } from '@fortawesome/pro-solid-svg-icons';
import { faAlignLeft } from '@fortawesome/pro-light-svg-icons';
import { faCalendar } from '@fortawesome/pro-light-svg-icons';
import { faCalendar as faCalendarSolid } from '@fortawesome/free-solid-svg-icons';
import { faStar } from '@fortawesome/pro-light-svg-icons';
import { faStar as faStarSolid } from '@fortawesome/pro-solid-svg-icons';
import { faList } from '@fortawesome/pro-light-svg-icons';
import { faFileUpload } from '@fortawesome/pro-light-svg-icons';
import { faCamera } from '@fortawesome/pro-light-svg-icons';
import { faTable } from '@fortawesome/pro-light-svg-icons';
import { faHashtag } from '@fortawesome/pro-light-svg-icons';
import { faCheckSquare } from '@fortawesome/pro-light-svg-icons';
import { faCommentMinus } from '@fortawesome/pro-light-svg-icons';
import { faCommentPlus } from '@fortawesome/pro-light-svg-icons';
import { faEllipsisHAlt } from '@fortawesome/pro-light-svg-icons';
import { faDotCircle } from '@fortawesome/pro-light-svg-icons';
import { faHeart } from '@fortawesome/free-solid-svg-icons';
import { faHeartBroken } from '@fortawesome/free-solid-svg-icons';
import { faSmile } from '@fortawesome/free-solid-svg-icons';
import { faSmile as faSmileLight } from '@fortawesome/pro-light-svg-icons';
import { faFrown } from '@fortawesome/pro-solid-svg-icons';
import { faMeh } from '@fortawesome/pro-solid-svg-icons';
import { faThumbsUp } from '@fortawesome/free-solid-svg-icons';
import { faThumbsDown } from '@fortawesome/free-solid-svg-icons';
import { faMinusCircle } from '@fortawesome/pro-light-svg-icons';
import { faFilePdf } from '@fortawesome/pro-light-svg-icons';
import { faMobile } from '@fortawesome/pro-light-svg-icons';
import { faTablet } from '@fortawesome/pro-light-svg-icons';
import { faDesktop } from '@fortawesome/pro-light-svg-icons';
import { faGripVertical } from '@fortawesome/pro-light-svg-icons';
import { faArrowToBottom } from '@fortawesome/pro-light-svg-icons';
import { faArrowRight } from '@fortawesome/pro-light-svg-icons';
import { faFileAlt } from '@fortawesome/pro-light-svg-icons';
import { faHourglass } from '@fortawesome/pro-light-svg-icons';
import { faRedo } from '@fortawesome/free-solid-svg-icons';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import { faPlus } from '@fortawesome/pro-light-svg-icons';
import { faUnderline } from '@fortawesome/pro-light-svg-icons';
import { faItalic } from '@fortawesome/pro-light-svg-icons';
import { faBold } from '@fortawesome/pro-light-svg-icons';
import { faLink } from '@fortawesome/pro-light-svg-icons';
import { faSave } from '@fortawesome/pro-light-svg-icons';
import { faChartPie } from '@fortawesome/pro-light-svg-icons';
import { faFileChartLine } from '@fortawesome/pro-light-svg-icons';
import { faLanguage } from '@fortawesome/pro-light-svg-icons';
import { faCode } from '@fortawesome/pro-light-svg-icons';
import { faTag as faSolidTag } from '@fortawesome/free-solid-svg-icons';
import { faFileChartPie } from '@fortawesome/pro-solid-svg-icons';
import { faChartBar } from '@fortawesome/pro-light-svg-icons';
import { faChartScatter } from '@fortawesome/pro-light-svg-icons';
import { faChartLine } from '@fortawesome/pro-light-svg-icons';
import { faTasksAlt } from '@fortawesome/pro-light-svg-icons';
import { faFilter } from '@fortawesome/pro-light-svg-icons';
import { faMinus } from '@fortawesome/pro-light-svg-icons';
import { faAt } from '@fortawesome/pro-light-svg-icons';
import { faEnvelope } from '@fortawesome/pro-light-svg-icons';
import { faEyeSlash } from '@fortawesome/pro-light-svg-icons';
import { faCodeBranch } from '@fortawesome/pro-light-svg-icons';
import { faCodeBranch as faCodeBranchThin } from '@fortawesome/pro-thin-svg-icons';
import { faCodeBranch as faCodeBranchSolid } from '@fortawesome/free-solid-svg-icons';
import { faCheck } from '@fortawesome/pro-light-svg-icons';
import { faCheck as faSolidCheck } from '@fortawesome/free-solid-svg-icons';
import { faThumbtack } from '@fortawesome/free-solid-svg-icons';
import { faGlobe } from '@fortawesome/pro-light-svg-icons';
import { faNewspaper } from '@fortawesome/pro-light-svg-icons';
import { faFile } from '@fortawesome/pro-light-svg-icons';
import { faBoxAlt } from '@fortawesome/pro-light-svg-icons';
import { faFacebook } from '@fortawesome/free-brands-svg-icons';
import { faTwitter } from '@fortawesome/free-brands-svg-icons';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faGooglePlay } from '@fortawesome/free-brands-svg-icons';
import { faAppStore } from '@fortawesome/free-brands-svg-icons';
import { faMicrosoft } from '@fortawesome/free-brands-svg-icons';
import { faGoogle } from '@fortawesome/free-brands-svg-icons';
import { faTachometer } from '@fortawesome/pro-light-svg-icons';
import { faUser } from '@fortawesome/pro-light-svg-icons';
import { faKey } from '@fortawesome/pro-light-svg-icons';
import { faPaperPlane } from '@fortawesome/pro-light-svg-icons';
import { faPaperPlane as faPaperPlaneSolid } from '@fortawesome/pro-solid-svg-icons';
import { faPlay } from '@fortawesome/pro-light-svg-icons';
import { faPause } from '@fortawesome/pro-light-svg-icons';
import { faFileCsv } from '@fortawesome/pro-light-svg-icons';
import { faPalette } from '@fortawesome/pro-light-svg-icons';
import { faBars } from '@fortawesome/pro-light-svg-icons';
import { faUsers } from '@fortawesome/pro-light-svg-icons';
import { faColumns } from '@fortawesome/pro-light-svg-icons';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { faAlignCenter } from '@fortawesome/pro-light-svg-icons';
import { faAlignRight } from '@fortawesome/pro-light-svg-icons';
import { faAlignJustify } from '@fortawesome/pro-light-svg-icons';
import { faUser as faUserThin } from '@fortawesome/pro-thin-svg-icons';
import { faUsers as faUsersThin } from '@fortawesome/pro-thin-svg-icons';
import { faPaperPlaneTop } from '@fortawesome/pro-thin-svg-icons';
import { faEnvelopeCircleCheck } from '@fortawesome/pro-thin-svg-icons';
import { faSmileBeam } from '@fortawesome/pro-solid-svg-icons';
import { faSmileBeam as faLightSmileBeam } from '@fortawesome/pro-light-svg-icons';
import { faFrown as faLightFrown } from '@fortawesome/pro-light-svg-icons';
import { faMeh as faLightMeh } from '@fortawesome/pro-light-svg-icons';
import { faHeart as faLightHeart } from '@fortawesome/pro-light-svg-icons';
import { faHeartBroken as faLightHeartBroken } from '@fortawesome/pro-light-svg-icons';
import { faThumbsUp as faLightThumbsUp } from '@fortawesome/pro-light-svg-icons';
import { faThumbsDown as faLightThumbsDown } from '@fortawesome/pro-light-svg-icons';
import { faSmileBeam as faRegularSmileBeam } from '@fortawesome/pro-regular-svg-icons';
import { faFrown as faRegularFrown } from '@fortawesome/pro-regular-svg-icons';
import { faMeh as faRegularMeh } from '@fortawesome/pro-regular-svg-icons';
import { faHeart as faRegularHeart } from '@fortawesome/pro-regular-svg-icons';
import { faHeartBroken as faRegularHeartBroken } from '@fortawesome/pro-regular-svg-icons';
import { faThumbsUp as faRegularThumbsUp } from '@fortawesome/pro-regular-svg-icons';
import { faThumbsDown as faRegularThumbsDown } from '@fortawesome/pro-regular-svg-icons';
import { faSmile as faRegularSmile } from '@fortawesome/pro-regular-svg-icons';
import { faAlignSlash } from '@fortawesome/pro-light-svg-icons';
import { faHeadSideBrain } from '@fortawesome/pro-light-svg-icons';
import { faCheckCircle } from '@fortawesome/pro-light-svg-icons';
import { faTachometerFast } from '@fortawesome/pro-light-svg-icons';
import { faBook } from '@fortawesome/pro-light-svg-icons';
import { faEdit } from '@fortawesome/pro-light-svg-icons';
import { faUserTag } from '@fortawesome/pro-light-svg-icons';
import { faUserTag as faSolidUserTag } from '@fortawesome/free-solid-svg-icons';
import { faArrowUpRightAndArrowDownLeftFromCenter } from '@fortawesome/pro-light-svg-icons';
import { faArrowDownLeftAndArrowUpRightToCenter } from '@fortawesome/pro-light-svg-icons';
import { faWandMagicSparkles } from '@fortawesome/pro-light-svg-icons';
import { faClockRotateLeft } from '@fortawesome/pro-light-svg-icons';
import { faBookSparkles } from '@fortawesome/pro-light-svg-icons';
import { faCrosshairsSimple } from '@fortawesome/pro-light-svg-icons';
import { faTag as faTagRegular } from '@fortawesome/pro-regular-svg-icons';
import { faGaugeSimpleHigh } from '@fortawesome/pro-light-svg-icons';
import { faRepeat } from '@fortawesome/pro-light-svg-icons';
import { faCodeCompare } from '@fortawesome/pro-light-svg-icons';
import { faDatabase } from '@fortawesome/pro-light-svg-icons';
import { faEnvelopeOpen } from '@fortawesome/pro-thin-svg-icons';
import { faBan } from '@fortawesome/pro-thin-svg-icons';
import { faFileCheck } from '@fortawesome/pro-thin-svg-icons';
import { faArrowPointer } from '@fortawesome/pro-thin-svg-icons';
import { faPartyHorn } from '@fortawesome/pro-light-svg-icons';


library.add(
    faHome,
    faTag,
    faTags,
    faCog,
    faShield,
    faShieldAlt,
    faClock,
    faAngleLeft,
    faAngleRight,
    faBullhorn,
    faAngleUp,
    faAngleDown,
    faChevronRight,
    faChevronLeft,
    faAngleDown,
    faQuestionCircle,
    faQuestionSquare,
    faTimes,
    faSolidTimes,
    faPen,
    faTrash,
    faSearch,
    faDownload,
    faCaretCircleUp,
    faPowerOff,
    faInfoCircle,
    faSolidInfoCircle,
    faSortDown,
    faSortUp,
    faChevronDown,
    faChevronUp,
    faSort,
    faListOl,
    faFileExcel,
    faEllipsisH,
    faEllipsisHLight,
    faExclamationCircle,
    faEye,
    faClone,
    faUpload,
    faPencil,
    faCopy,
    faSearchPlus,
    faSearchMinus,
    faExclamationTriangle,
    faFacebookF,
    faLinkedinIn,
    faPlusCircle,
    faPlusSolidCircle,
    faLightbulb,
    faChevronDoubleDown,
    faChevronDoubleUp,
    faTimesCircle,
    faTimesSolidCircle,
    faAlignLeft,
    faCalendar,
    faCalendarSolid,
    faStar,
    faStarSolid,
    faList,
    faFileUpload,
    faCamera,
    faTable,
    faHashtag,
    faCheckSquare,
    faCommentMinus,
    faCommentPlus,
    faEllipsisHAlt,
    faDotCircle,
    faHeart,
    faSmile,
    faSmileLight,
    faThumbsUp,
    faMinusCircle,
    faFilePdf,
    faMobile,
    faTablet,
    faDesktop,
    faGripVertical,
    faArrowToBottom,
    faArrowRight,
    faFileAlt,
    faHourglass,
    faRedo,
    faLock,
    faPlus,
    faUnderline,
    faItalic,
    faBold,
    faLink,
    faSave,
    faChartPie,
    faFileChartLine,
    faLanguage,
    faCode,
    faSolidTag,
    faFileChartPie,
    faFilter,
    faMinus,
    faFileChartPie,
    faChartBar,
    faChartLine,
    faChartScatter,
    faTasksAlt,
    faAt,
    faEnvelope,
    faEyeSlash,
    faCheck,
    faSolidCheck,
    faThumbtack,
    faCodeBranch,
    faCodeBranchThin,
    faCodeBranchSolid,
    faNewspaper,
    faGlobe,
    faFile,
    faBoxAlt,
    faFacebook,
    faTwitter,
    faInstagram,
    faGooglePlay,
    faAppStore,
    faMicrosoft,
    faGoogle,
    faTachometer,
    faUser,
    faKey,
    faPaperPlane,
    faPlay,
    faPause,
    faFileCsv,
    faPalette,
    faBars,
    faUsers,
    faPaperPlaneSolid,
    faColumns,
    faCircle,
    faAlignCenter,
    faAlignRight,
    faAlignJustify,
    faUsersThin,
    faUserThin,
    faPaperPlaneTop,
    faEnvelopeCircleCheck,
    faThumbsDown,
    faSmileBeam,
    faFrown,
    faMeh,
    faHeartBroken,
    faSmileBeam,
    faLightSmileBeam,
    faLightFrown,
    faLightMeh,
    faLightHeart,
    faLightHeartBroken,
    faLightThumbsUp,
    faLightThumbsDown,
    faRegularSmileBeam,
    faRegularFrown,
    faRegularMeh,
    faRegularHeart,
    faRegularHeartBroken,
    faRegularThumbsUp,
    faRegularThumbsDown,
    faRegularSmile,
    faAlignSlash,
    faHeadSideBrain,
    faCheckCircle,
    faTachometerFast,
    faBook,
    faEdit,
    faUserTag,
    faSolidUserTag,
    faArrowUpRightAndArrowDownLeftFromCenter,
    faArrowDownLeftAndArrowUpRightToCenter,
    faWandMagicSparkles,
    faClockRotateLeft,
    faBookSparkles,
    faCrosshairsSimple,
    faTagRegular,
    faGaugeSimpleHigh,
    faRepeat,
    faCodeCompare,
    faDatabase,
    faEnvelopeOpen,
    faBan,
    faFileCheck,
    faArrowPointer,
    faPartyHorn
);

config.autoAddCss = true;

export const FontAwesomeIcon = FontAwesomeIcons;
