import { ref, reactive } from 'vue';

interface ConfirmPopupDetails {
    title: string|null,
    body: string|null,
    type: string|null,
    primaryText: string|null,
    secondaryText: string|null,
    isWarning: boolean|'error',
    secondaryButtonType: string|null,
}

export const confirmPopupDetails = reactive<ConfirmPopupDetails>({
    title: null,
    body: null,
    type: null,
    primaryText: null,
    secondaryText: null,
    isWarning: false,
    secondaryButtonType: null,
});
export const isVisible = ref(false);
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const isConfirmed = ref((value) => {});

export const open = (title, body, type, primaryText: string|null = null, secondaryText: string|null = null, isWarning: boolean|'error' = false, secondaryButtonType = null as string|null) => {
    isVisible.value = true;
    confirmPopupDetails.title = title;
    confirmPopupDetails.body = body;
    confirmPopupDetails.type = type;
    confirmPopupDetails.primaryText = primaryText;
    confirmPopupDetails.secondaryText = secondaryText;
    confirmPopupDetails.isWarning = isWarning;
    confirmPopupDetails.secondaryButtonType = secondaryButtonType;
    return new Promise(resolve => {
        isConfirmed.value = (isConfirmed: boolean) => {
            resolve(isConfirmed);
            isVisible.value = false;
        };
    });
};
export default {
    default(title: string, body: string, primaryText = null, secondaryText = null, isWarning = false) {
        return open(title, body, 'default', primaryText, secondaryText, isWarning);
    },
    destruction(title: string, body: string, primaryText = null, secondaryText = null, isWarning: boolean|'error' = false, secondaryButtonType = null as string|null) {
        return open(title, body, 'destructive', primaryText, secondaryText, isWarning, secondaryButtonType);
    },
    notification(title: string, body: string, primaryText = null, secondaryText = null, isWarning = false) {
        return open(title, body, 'notification', primaryText, secondaryText, isWarning);
    }
};
