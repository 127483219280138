import i18next from 'i18next';
import Backend from 'i18next-http-backend';
import globals from '@/utils/globals';
import debounce from 'lodash/debounce';

const apiKey = import.meta.env.VITE_APP_SIMPLELOCALIZE_API_KEY; // only use in development

let missingTranslations = [];

const uploadMissingTranslations = debounce(async () => {
    try {
        await fetch('https://api.simplelocalize.io/api/v1/translations?importOptions=REPLACE_TRANSLATION_IF_FOUND', {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'X-SimpleLocalize-Token': apiKey,
            },
            body: JSON.stringify({
                content: missingTranslations
            }),
        });

        missingTranslations = [];
    }
    catch (e) {
        // eslint-disable-next-line no-console
        console.error(e.message);
    }
}, 1000);

const missingKeyHandler = (_languages, _ns, key, fallbackValue) => {
    if (!missingTranslations.some(trans => trans.key === key)) {
        missingTranslations.push({
            key,
            language: 'en',
            text: fallbackValue,
        });
    }

    uploadMissingTranslations();
};

export default async (locale) => {
    await i18next
        .use(Backend)
        .init({
            lng: locale || 'en',
            fallbackLng: 'en',
            keySeparator: false,
            backend: {
                loadPath: `https://cdn.simplelocalize.io/${globals.getSimpleLocalizeId()}/_latest/{{lng}}`,
            },
            saveMissing: !!apiKey,
            missingKeyHandler,
        });
    return i18next;
};
