export interface CustomLabelState {
    customLabels: CustomLabel[];
    sort: {
        by: 'created_at' | 'name' | 'synonyms' | 'languages' | 'author',
        direction: 'asc' | 'desc'
    };
    users: User[],
    categories: Category[],
    searchQuery: string|null;
    languageIdFilter: number;
    dateFilter: {
        date: string;
        start: string;
        end: string;
    };
    filters: any[],
    popup: PopupState;
    upload: UploadState;
}

export interface PopupState {
    open: boolean;
    mode: 'edit' | 'clone' | 'new' | null;
    customLabel: CustomLabel | null;
}

export interface UploadState {
    sheets: any;
    activeSheet: string;
    file: File | null;
    isUploadInProgress: boolean;
    completedRows: number;
    columnTypeEnum: any;
    languageId: number;
    hasEmpty: boolean;
    hasDuplication: boolean;
}

export interface CustomLabel {
    category: string|null,
    category_id: number|null,
    code: any,
    created_at: string,
    creator: Creator|string,
    creator_id: number,
    deleted_at: string|null,
    editor_id: number,
    excludesList: Array<string>,
    filters: string,
    filtersList: Array<string>,
    id: number,
    name: string,
    synonymsList: Array<string>,
    tags: string[],
    updated_at: string,
    user_group_id: number,
    phraseSets: PhraseSet[],
    languages: number[],
}

export interface PhraseSet {
    languageId: number
    synonyms: string[],
    excludes: string[]
}

export interface Creator {
    full_name: string
}

interface User {
    fullName: string
}

export interface Category {
    id: number,
    name: string,
    user_group_id: number
}

export enum CLResponseTypeEnum {
    //If you need more complex data on getCustomLabels, like creator, simple phrase set details (all languages, synonyms, excludes) or full phrase set json, you should set query param
    // Response contains basic cl data + the data get by the query param
    ONLY_CREATOR=1,
    ONLY_SIMPLE_PHRASE_SET=2,
    CREATOR_AND_SIMPLE_PHRASE_SET=3,
    FULL_PHRASE_SET=4, // simple phrase set details + full phrase set json
    FULL_CL=5 // Use this only if you need everything, this can be slower

}

export enum GroupByEnum {
    NO_GROUPING,
    BY_CATEGORY,
    BY_TAG,
    BY_CREATOR,
    BY_LANGUAGE,
}
